import React from 'react';
import {
    getPageContent,
} from './ServicesOverviewPageData';
import { StyleGrid } from '@fjordkraft/fjordkraft.component.library';
import {
    useAddonServicesContext,
    useSubPageLayoutContext,
} from '../../../contexts';
import { IPlankHouse, PlankHouseBlock, ArticlesBlock } from '../../../blocks';
import { Constants } from '../../../data';
import { IArticleItem } from '../../../components';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import './ServicesOverviewPage.scss';

interface IServiceOverviewPage extends IDefaultViewProps {
    house: IPlankHouse;
    availableServices: {
        title: string;
        items: IArticleItem[];
    }
}

export const ServicesOverviewPage = () => {
    // ************************************
    // Properties
    // ************************************

    const classPrefix = 'services-overview-page';
    const { setShowInstallationSelector } = useSubPageLayoutContext();
    const { addonStates } = useAddonServicesContext();

    // ************************************
    // Render
    // ************************************

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.services.type,
                usesSubPage: true,
                pageClamp: 'clamp'
            },
            dependencies: [
                { addonStates },
                { setShowInstallationSelector } 
            ],
            onDataChange: (config: IServiceOverviewPage) => {
                const { userData } = config.user;

                setShowInstallationSelector((userData?.installations && userData.installations.length > 1) ?? false);
            },
            handleData: getPageContent,
            render: (config: IServiceOverviewPage) => {
                const { availableServices, activeBrand, activeTheme, house } = config;

                return (
                    <StyleGrid
                        className={classPrefix}
                        direction="column"
                        alignment="top-center"
                        gap={3}
                    >
                        {house && (
                            <PlankHouseBlock
                                {...house}
                                brand={activeBrand}
                                theme={activeTheme}
                                className={`${classPrefix}__house`}
                            />
                        )}
                        {availableServices && (
                            <ArticlesBlock
                                {...availableServices}
                                brand={activeBrand}
                                theme={activeTheme}
                            />
                        )}
                    </StyleGrid>
                )
            }
        })
    );
};
