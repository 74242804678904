import { isEmpty } from 'lodash';
import { getRequest, postRequest } from '../../contexts';

export const fetchBenefits = async (GET: getRequest) => {
	let resp = await GET('benefits');
	return resp?.data;
};

export const fetchEarnPointsCategory = async (GET: getRequest) => {
	let resp = await GET(`benefits/earnpointscategory`);
	return resp?.data?.replaceAll(' ', '_');
};

export const fetchRecruitLink = async (GET: getRequest) => {
	let resp = await GET('referrerid');
	let baseUrl = 'https://www.fjordkraft.no/strom/verve/bestill-strom/?referrer_id=';
	return baseUrl + resp.data?.id;
};

export const fetchKickbackSummary = async (GET: getRequest) => {
	let resp = await GET('kickback', true);

	if (resp.callState === 'success' && resp.data && !isEmpty(resp.data)) {
		return resp.data;
	} else {
		return {
			amount: 0,
			hasOnboarded: false,
			expiringPoints: null,
		};
	}
};

export const fetchKickbackTransactions = async (GET: getRequest) => {
	let resp = await GET('kickback/transactions');
	return resp?.data;
};

export const postKickbackDeduction = async (POST: postRequest, amount: number) => {
	let resp = await POST(`kickback/deduct/invoice?amount=${amount}`, undefined);
	return resp;
};
