import { CallState } from '@fjordkraft/fjordkraft.component.library';
import { Constants } from '../data';
import { logger } from './collection/HelperService';

// ************************************
// Properties / Interfaces / Types
// ************************************

export type AsyncMethodTypes = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type AsyncCallRole = 'OWNER';

/**
 *   IAsyncHandler
 *   @type {string} token (required)
 *   @type {string} additions (required)
 *   @type {AsyncMethodTypes} method (required)
 *   @type {AbortController} abortController (optional)
 *   @type {string} role (optional)
 *   @type {any} body (optional)
 */
export interface IAsyncHandler {
	token: string;
	additions: string;
	method: AsyncMethodTypes;
	abortController?: AbortController;
	role?: AsyncCallRole;
	body?: any;
	hostIdForCustomerDataRequests?: string;
}

export interface IResponse {
	callState: CallState;
	data: any;
}

export interface ITypedResponse<T> {
	callState: CallState;
	data: T | null;
}

// ************************************
// Functionlity (Public)
// ************************************

export const handleAsyncData = async (config: IAsyncHandler): Promise<IResponse> => {
	_localDevRequestGate(config);

	let r = (await _fetcher(config)) as IResponse;
	let data = await r.data;

	if (data != undefined && data != null && r?.callState) {
		return { data, callState: r.callState };
	} else {
		logger(`handleAsyncData: found no data. \n Attempt with: ${config.additions}`, 'warn');
		return { data: null, callState: r.callState };
	}
};

// ************************************
// Functionlity (Private)
// ************************************

const _fetcher = async (config: IAsyncHandler): Promise<IResponse | void> => {
	let { token, additions, abortController, body, method } = config;
	let { url, version, brand } = Constants.api;
	let query = `${url}/${version}/${brand}/`;
	let asyncResponse: IResponse = { callState: 'idle', data: null };

	if (!token) {
		logger(`handleAsyncData failed: No access token assigned.`, 'error');
		return asyncResponse;
	}

	if (additions) {
		query += additions;
	}

	return await fetch(query, {
		method,
		signal: abortController?.signal ?? undefined,
		headers: _getHeader(config),
		credentials: 'include',
		body,
	})
		.then(_handleErrors)
		.then((response) => {
			return _handleAsyncResponse(response, method) as IResponse;
		})
		.catch((error: any) => {
			asyncResponse.callState = 'error';
			if (error.name === 'AbortError') {
				logger(`Aborted: ${error}`, 'error');
			} else {
				logger(
					`_fetcher failed: 
                    \n ${error}. 
                    \n Brand: ${brand} 
                    \n Version: ${version}
                    \n Additions: ${additions}
                    \n Method: ${method}`,
					'error'
				);
			}

			return asyncResponse;
		});
};

const _getHeader = (config: IAsyncHandler): HeadersInit => {
	const { token, hostIdForCustomerDataRequests } = config;

	let header = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	};

	if (hostIdForCustomerDataRequests) {
		header = { ...header, ...{ 'x-host-id': hostIdForCustomerDataRequests } };
	}

	return header;
};

const _handleErrors = (response: Response) => {
	if (!response.ok) {
		throw Error(response.status + ' ' + response.statusText);
	}
	return response;
};

const _handleAsyncResponse = (response: Response, method: AsyncMethodTypes) => {
	switch (method) {
		case 'GET':
			return _handleGETResponse(response);
		case 'PUT':
			return _handlePUTResponse(response);
		case 'DELETE':
			return _handleDELETEResponse(response);
		case 'POST':
			return _handlePOSTResponse(response);
		case 'PATCH':
			return _handlePATCHResponse(response);
	}
};

const _handleGETResponse = (response: Response) => {
	let callState: CallState = 'success';

	const contentType = response.headers.get('content-type');

	if (contentType && contentType.indexOf('application/json') !== -1) {
		return { callState, data: response.json() };
	} else {
		return { callState, data: response.text() };
	}
};

const _handlePUTResponse = (response: Response) => {
	let callState: CallState = 'success';
	return { callState, data: response.status.toString() };
};

const _handleDELETEResponse = (response: Response) => {
	let callState: CallState = 'success';
	return { callState, data: response.status.toString() };
};

const _handlePOSTResponse = (response: Response) => {
	let callState: CallState = 'success';

	const contentType = response.headers.get('content-type');

	if (contentType && contentType.indexOf('application/json') !== -1) {
		return { callState, data: response.json() };
	} else {
		return { callState, data: response.text() };
	}
};

const _handlePATCHResponse = (response: Response) => {
	let callState: CallState = 'success';

	const contentType = response.headers.get('content-type');

	if (contentType && contentType.indexOf('application/json') !== -1) {
		return { callState, data: response.json() };
	} else {
		return { callState, data: response.text() };
	}
};

const _localDevRequestGate = (config: IAsyncHandler) => {
	if (config.method !== 'GET' && Constants.appEnv === 'prod' && Constants.isLocalHost) {
		throw Error(
			`Only GET requests allowed in local development towards prod.\nRequest blocked: ${config.method} - ${config.additions} - body: ${config.body}`
		);
	}
};