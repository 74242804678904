import { Constants } from '../../data';
import {
	ICustomerElectricityAgreement,
	ICustomerElectricityAgreementDetails,
	ICustomerInstallation,
	IDefaultProps,
} from '../../models';
import { createString, getText, getTextV2 } from '../../services';
import { IMoreAboutAgreement, IShowMoreAboutAgreement } from './EnergyAgreementPage';
import { format } from 'date-fns';
import { getRequest } from '../../contexts';
import { getEnergyAgreementOptions, getChartData, getParsedChartAccordionData } from './AdditionalDataHandling';

export interface IEnergyAgreementPageData extends IDefaultProps {
	showMoreAboutAgreement: IShowMoreAboutAgreement;
	setShowMoreAboutAgreement: (state: IShowMoreAboutAgreement) => void;
	setShowCancelSpotManaged: (state: boolean) => void;
}

export interface IAgreementGetData extends IEnergyAgreementPageData {
	agreement: ICustomerElectricityAgreement;
	details: ICustomerElectricityAgreementDetails;
	detailsList?: string[];
	benefits: any[];
	reducedOptions?: boolean;
}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IEnergyAgreementPageData) => {
	const { translations, user, services, showMoreAboutAgreement, desktopView } = config;
	const { installation } = user;
	const { GET } = services;

	if (installation && showMoreAboutAgreement) {
		let agreementResp = await GET(`Agreements/${installation.meterId}`);
		let agreementDetailsResp = await GET(`Agreements/${installation.meterId}/description`);
		let agreement: ICustomerElectricityAgreement = agreementResp.data;
		let details: ICustomerElectricityAgreementDetails = agreementDetailsResp.data;
		let agreementInformation = fetchEnergyAgreementInformation(translations, agreement);
		let parsedChartData = undefined;
		let parsedChartAccordionData = undefined;
		let cancellationDate: Date | undefined = undefined; // TODO: Need to get date for cancellation

		if (agreement.agreementCategory === 'SpotPrice' && agreementInformation) {
			parsedChartData = await getChartData(translations, installation, agreement, GET);

			if (parsedChartData) {
				parsedChartData.cardHeader.title =
					getTextV2({
						key: 'title',
						translations: agreementInformation,
						textsKey: 'agreementTexts',
						includeMissing: false,
					}) ?? '';
				parsedChartData.cardHeader.subTitle =
					getTextV2({
						key: 'subTitle',
						translations: agreementInformation,
						textsKey: 'agreementTexts',
						includeMissing: false,
					}) ?? '';
				parsedChartData.description =
					getTextV2({
						key: 'description',
						translations: agreementInformation,
						textsKey: 'agreementTexts',
						includeMissing: false,
					}) ?? '';
			}
		} else if (agreement.agreementCategory === 'Managed') {
			parsedChartAccordionData = getParsedChartAccordionData(
				agreement,
				translations,
				desktopView,
				installation.address.priceArea ?? ''
			);
		}

		return {
			...config,
			sub: {
				title: getText('pageTitle', translations),
				back: {
					text: installation.address.streetAddress ? installation.address.streetAddress : '',
					link: Constants.paths.energyPage,
				},
			},
			introduction: {
				title: agreement?.name ?? '',
				description: agreement?.productDescription ?? '',
			},
			details: agreementDetailsResp.data,
			installation,
			campaignDescription: getCampaignDescription(agreement),
			agreementOptions: getEnergyAgreementOptions({
				...config,
				...{
					benefits: agreementInformation?.benefitPlanks,
					agreement,
				},
			} as IAgreementGetData),
			reducedAgreementOptions: getEnergyAgreementOptions({
				...config,
				...{
					benefits: agreementInformation?.benefitPlanks,
					agreement,
					reducedOptions: true,
				},
			} as IAgreementGetData),
			chartData: parsedChartData,
			agreement,
			futureAgreement: {
				translation: translations,
				details,
				description: '',
			},
			chartAccordion: parsedChartAccordionData,
			cancellationNotice: cancellationDate
				? {
						title: createString(getText('noticePeriodTitle', translations), {
							date: format(cancellationDate, 'dd.MM.yyyy'),
						}),
						description: getText('noticePeriodDescription', translations),
				  }
				: undefined,
		};
	}
};

export const getMoreAboutAgreement = (config: IAgreementGetData) => {
	const { translations, agreement, detailsList } = config;

	return {
		house: getEnergyAgreementOptions(config),
		top: {
			title: agreement.name,
			description: agreement.productDescription,
			translations,
		},
		details: detailsList,
		translation: translations,
		campaignDescription: agreement.campaignDescription,
	} as IMoreAboutAgreement;
};

const fetchEnergyAgreementInformation = (translations: any, agreement: ICustomerElectricityAgreement) => {
	if (agreement?.agreementCategory && translations?.benefitPlanksDefault) {
		switch (agreement.agreementCategory) {
			case 'SpotPrice':
				return translations.benefitPlanksSpotprice;
			case 'Managed':
				return translations.benefitPlanksManaged;
			case 'VariableRate':
				return translations.benefitPlanksVariableRate;
			default:
				return translations.benefitPlanksDefault;
		}
	}
};

export const fetchCustomerAgreementData = async (installation: ICustomerInstallation, GET: getRequest) => {
	if (installation) {
		let agreementResp = await GET(`Agreements/${installation.meterId}`);
		return agreementResp;
	}
};

export const getCampaignDescription = (agreement: ICustomerElectricityAgreement) => {
	return agreement?.campaignDescription;
};
