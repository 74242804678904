import React from 'react';
import { 
    BaseComponent, 
    ContentGrid, 
    getCounterTheme, 
    IComponent, 
    IComponentTemplate, 
    IconType, 
    StyleGrid, 
    Text 
} from "@fjordkraft/fjordkraft.component.library";
import { MobileSelectMenuTemplate } from './MobileSelectMenuTemplate';
import classNames from 'classnames';
import { ActionButton } from '../Buttons/ActionButton';
import { h4TextPrefab, MS_ButtonTemplate } from '../../Prefabs';
import { v4 as uuid4 } from 'uuid';
import './MobileSelectMenu.scss';

export interface IMobileSelectSection {
    title?: string;
    items: any[];
}

export interface IMobileSelectMenu extends IComponent {
    template?: IComponentTemplate;
    items: IMobileSelectSection[];
    onClose: () => void;
}

export const MobileSelectMenu = (props: IMobileSelectMenu) => {
    const { id, className, theme = 'Light', brand, template = MobileSelectMenuTemplate(theme), onClose, items } = props;
    const classPrefix = 'mobile-select-menu';

    const _renderSections = () => {
        return items.map((item: IMobileSelectSection) => {
            return(_renderSection(item));
        });
    }

    const _renderItems = (items: any[]) => {
        return items.map((item: any) => {
            return(item);
        })
    }

    const _renderSection = (item: IMobileSelectSection) => {
        return(
            <ContentGrid
                key={uuid4()}
                className={`${classPrefix}__content__section`}
                direction='column'
                alignment='top-left'
                spaceBetween
                tagType='nav'
                boxSizing='border-box'
                gap={1}
            >
                {item.title &&
                    <Text 
                        {...h4TextPrefab()}
                        brand={brand} 
                        theme={getCounterTheme(theme)}
                        faded
                    >
                        {item.title}
                    </Text>
                }
                {_renderItems(item.items)}
            </ContentGrid>
        );
    }

    return(
        <BaseComponent
            id={id}
            className={classNames(classPrefix, {
                [`${className}`]: className
            })}
            theme={theme}
            brand={brand}
            template={template}
        >
            <ContentGrid
                className={`${classPrefix}__header`}
                direction='row'
                alignment='center-right'
                tagType='nav'
                boxSizing='border-box'
            >
                <ActionButton
                    template={MS_ButtonTemplate(theme, 'menu-icon')}
                    customization={{
                        icon: {
                            width: 1.5,
                            height: 1.5
                        }
                    }}
                    action={{
                        icon: IconType.Close,
                        onClick: onClose
                    }} 
                />
            </ContentGrid>
            <StyleGrid
                className={`${classPrefix}__content`}
                direction={'column'}
                alignment='top-left'
                gap={4}
                boxSizing='border-box'
            >
                {_renderSections()}
            </StyleGrid>
        </BaseComponent>
    );
}