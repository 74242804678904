import { BrandColors, IconType, IMasterPlank } from '@fjordkraft/fjordkraft.component.library';
import { IPlankHouse } from '../../../blocks';
import { Constants } from '../../../data';
import { AllowFeature, HomePageData, IsActiveCustomer, getText, getTextV2, isNonCustomer } from '../../../services';
import { ITextPlankPrefab, getPlankPrefab } from '../../../Prefabs';
import { ICustomer, IDefaultProps } from '../../../models';

export interface IMyPageHomeData extends IDefaultProps {useNewConsents: boolean}

export const getPageContent = async (config: IMyPageHomeData) => {
	const { user, translations } = config;
	const { userData } = user;


	if (translations && userData) {
		return {
			...config,
			sub: {
				title: getText('pageTitle', translations),
				back: {
					text: getText('back', translations),
					link: Constants.paths.energyPage,
				},
				subTitle: undefined,
			},
			customerInformation: _getCustomerInformation(userData),
			house: _getHouse(config),
		};
	}
};

const _getCustomerInformation = (userData: ICustomer) => {
	return {
		name: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`,
		birthdate: userData?.birthdate ? new Date(userData?.birthdate) : new Date(),
		customerNumber: `${userData?.customerNumber ?? ''}`,
		phoneNumber: userData.contactInformation?.phone ?? '',
		email: userData.contactInformation?.email ?? '',
		homeAddress: `${userData?.address?.streetAddress ?? ''} ${userData?.address?.postalLocation ?? ''} ${
			userData?.address?.postalLocation ?? ''
		}`,
	};
};

const _getHouse = (config: IMyPageHomeData) => {
	const { user, translations, desktopView, services, relationship, epiChildren, useNewConsents } = config;
	const { userData, mainUser } = user;
	const { customerServiceFeature } = services;
	const { isGuest, hosts } = relationship;

	let planks: IMasterPlank[] = [];

	// Move and invoice settings plank should not be visible if the userData is not active.
	if (userData && IsActiveCustomer(userData.installations[0])) {
		planks.push(
			_getPlankPrefab({
				title: getText('plankMoveTitle', translations),
				description: getTextV2({
					key: 'plankMoveDesc',
					translations,
					includeMissing: false
				}),
				link: Constants?.paths?.movePage ?? '',
				icon: IconType.Trolley,
				desktopView
			})
		);
		planks.push(
			_getPlankPrefab({
				title: getText('plankInvoiceSettingsTitle', translations),
				description: getTextV2({
					key: 'plankInvoiceSettingsDesc',
					translations,
					includeMissing: false
				}),
				link: `${Constants.paths.userPage}${Constants.paths.invoiceSettings}`,
				icon: IconType.CogLowDetail,
				desktopView
			})
		);
	}

	planks.push(
		_getPlankPrefab({
			title: getText('plankCommunicationTitle', translations),
			description: getTextV2({
				key: 'plankCommunicationDesc',
				translations,
				includeMissing: false
			}),
			link: `${Constants?.paths?.userCommunication}`,
			icon: IconType.Phone,
			desktopView
		})
	);

	let rootPage = HomePageData(epiChildren);

	if (
		AllowFeature(Constants.features.guestAdmin, rootPage, services.user, customerServiceFeature) &&
		(!isGuest || (isGuest && isNonCustomer(hosts, mainUser)))
	) {
		planks.push(
			_getPlankPrefab({
				title: getText('plankGuestTitle', translations),
				description: getTextV2({
					key: 'plankGuestDesc',
					translations,
					includeMissing: false
				}),
				link: `${Constants.paths.userPage}${Constants.paths.guests}`,
				icon: IconType.PeopleKey,
				desktopView
			})
		);
	}
	
	if(useNewConsents && AllowFeature(Constants.features.consentPage, rootPage, services.user, customerServiceFeature)) {
		planks.push(
			_getPlankPrefab({
				title: getText('plankConsentTitle', translations),
				description: getTextV2({
					key: 'plankConsentDesc',
					translations,
					includeMissing: false
				}),
				link: `${Constants?.paths?.consentPage}`,
				icon: IconType.Clipboard,
				desktopView
			})
		);
	}

	if(useNewConsents && AllowFeature(Constants.features.reservationPage, rootPage, services.user, customerServiceFeature)) {
		planks.push(
			_getPlankPrefab({
				title: getText('plankReservedTitle', translations),
				description: getTextV2({
					key: 'plankReservedDesc',
					translations,
					includeMissing: false
				}),
				link: `${Constants?.paths?.reservationPage}`,
				icon: IconType.Clipboard,
				desktopView
			})
		);
	}

	let house: IPlankHouse = {
		plankWalls: [
			{
				planks: planks,
			},
		],
	};

	return house;
};

interface IPlankPrefab {
	title?: string;
	description?: string;
	link?: string;
	icon?: IconType;
	desktopView?: boolean;
}

const _getPlankPrefab = (config: IPlankPrefab) => {
	const { title, description, link, icon, desktopView = true} = config;

	return getPlankPrefab('Text', {
		action: {
			link,
			useRouterLink: true,
		},
		left: {
			icon,
			title,
			description,
			customization: {
				icon: {
					color: BrandColors['primary-shade-light-2'],
				},
			},
		},
		right: {
			icon: IconType.ChevronRight,
		},
		desktopView,
	} as ITextPlankPrefab);
};