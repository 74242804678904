import React, { useEffect, useMemo, useState } from 'react';
import { BrandColors, IconType } from '@fjordkraft/fjordkraft.component.library';
import { ICustomer, ICustomerAccountInformation, ICustomerInstallation } from '../../../models';
import { IMSPlankWall, PlankHouseBlock } from '../../../blocks';
import { createString, getAddressesBasedOnAgreement, getText } from '../../../services';
import {
	ApplicationAccountSelectContext,
	useApplicationContext,
	useApplicationCoreDataContext,
} from '../../../contexts';
import { getPlankPrefab } from '../../../Prefabs';
import { PopupCard } from '../../../modals';
import _ from 'lodash';
import './ApplicationAccountSelectWrapper.scss';

export const ApplicationAccountSelectWrapper = (props: any) => {
	const { activeBrand, activeTheme, desktopView } = useApplicationContext();
	const { userData, translation } = useApplicationCoreDataContext();
	const classPrefix = 'application-account-select-wrapper';

	// ************************************
	// Lifecycle
	// ************************************

	const [includeAllOption, setIncludeAllOption] = useState<boolean>(true);
	const [showAccountSelectMenu, setShowAccountSelectMenu] = useState<boolean>(false);
	const [useAccountSelectMenu, setUseAccountSelectMenu] = useState<boolean>(false);
	const [activeAccount, setActiveAccount] = useState<ICustomerAccountInformation>();
	const [selectedAccount, setSelectedAccount] = useState<ICustomerAccountInformation>();

	const _applicationAccountSelectWrapperContext = useMemo(() => {
		return {
			includeAllOption,
			setIncludeAllOption,
			useAccountSelectMenu,
			setUseAccountSelectMenu,
			activeAccount,
			setActiveAccount,
			selectedAccount,
			setSelectedAccount,
		};
	}, [useAccountSelectMenu, activeAccount, selectedAccount, includeAllOption]);

	useEffect(() => {
		if (userData && _canSetInitialActiveAccount(userData, activeAccount)) {
			setActiveAccount(_getActiveAccount(userData, activeAccount));
		}
	}, [userData]);

	const _canSetInitialActiveAccount = (userData: ICustomer, existingAccount?:ICustomerAccountInformation) : boolean => {
		if(!existingAccount) {
			return true;
		}

		if(userData?.accounts?.length) {
			for(const acc of userData.accounts) {
				if(acc.accountId === existingAccount.accountId) {
					return !_.isEqual(existingAccount, acc);
				}	
			}
		}

		return false;
	}

	const _getActiveAccount = (userData: ICustomer, existingAccount?:ICustomerAccountInformation) => {
		if(existingAccount) {
			for(const acc of userData.accounts) {
				if(acc.accountId === existingAccount.accountId) {
					return acc;
				}	
			}
		}

		return userData.accounts[0];
	}

	// ************************************
	// Account data handling
	// ************************************

	const getInvoiceAgreementOptions = (
		userData: ICustomer,
		translations: any,
		selectedAccount?: ICustomerAccountInformation
	) => {
		let walls: IMSPlankWall[] = [];

		if (userData.accounts.length > 0) {
			if (includeAllOption) {
				walls.push({
					planks: [
						getPlankPrefab('Radio', {
							left: {
								title: createString(getText('plankInvoiceAccountTitle', translations), {
									amount: userData.accounts.length,
								}),
								description: createString(getText('plankInvoiceAccountDesc', translations), {
									amount: userData.installations.length,
								}),
							},
							right: {
								active: !selectedAccount,
								value: undefined,
								onChange: () => {
									setSelectedAccount(undefined);
								},
							},
						}),
					],
				} as IMSPlankWall);
			}

			walls = walls.concat(
				userData.accounts.map((account: ICustomerAccountInformation) => {
					return {
						planks: [_getInvoiceAgreementInstallations(userData.installations, account, selectedAccount)],
					} as IMSPlankWall;
				})
			);
		}

		return walls;
	};

	const _getInvoiceAgreementInstallations = (
		installations: ICustomerInstallation[],
		account: ICustomerAccountInformation,
		selectedAccount?: ICustomerAccountInformation
	) => {
		let installationTexts: string[] = [];
		let active: boolean = selectedAccount?.accountId === account?.accountId;

		if (installations.length > 0) {
			installations.forEach((installation: ICustomerInstallation) => {
				if (installation.accountId === account.accountId) {
					installationTexts.push(installation.address.streetAddress);
				}
			});
		}

		let plank = getPlankPrefab('Radio', {
			left: {
				title: getText('plankInvoiceAgreement', translation),
				description: account.accountId,
			},
			right: {
				active,
				value: account,
				onChange: () => {
					setSelectedAccount(account);
				},
			},
			bottom: {
				title: installationTexts.toString(),
			},
		});

		return plank;
	};

	const _getAccountSelectPlank = (
		translations: any,
		userData: ICustomer,
		activeAccount?: ICustomerAccountInformation
	) => {
		let title: string = createString(getText('plankInvoiceAccountTitle', translations), {
			amount: userData.accounts.length,
		});
		let description: string = createString(getText('plankInvoiceAccountDesc', translations), {
			amount: userData.installations.length,
		});

		if (activeAccount) {
			title = createString(getText('plankActiveInvoiceAccountTitle', translations), {
				accountNumber: activeAccount.accountId,
			});
			description = getAddressesBasedOnAgreement({ userData, accountId: activeAccount.accountId });
		}

		return {
			title: getText('invoiceWallAccountFilterTitle', translations),
			planks: [
				getPlankPrefab('Text', {
					action: {
						onClick: () => {
							setShowAccountSelectMenu(true);
						},
					},
					left: {
						icon: IconType.Files,
						title,
						description,
						customization: {
							icon: {
								type: IconType.Files,
								color: BrandColors['primary-shade-light-2'],
							},
						},
					},
					right: {
						title: getText('plankInvoiceAccountValue', translations),
						icon: IconType.ChevronRight,
					},
				}),
			],
		} as IMSPlankWall;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderAccountSelectHouse = useMemo(() => {
		if (useAccountSelectMenu && translation && userData?.accounts && userData.accounts.length > 1) {
			return (
				<PlankHouseBlock
					className={`${classPrefix}__house`}
					theme={activeTheme}
					brand={activeBrand}
					plankWalls={[_getAccountSelectPlank(translation, userData, activeAccount)]}
				/>
			);
		}
	}, [useAccountSelectMenu, userData, translation, activeAccount]);

	const _renderHouseBlock = useMemo(() => {
		if (userData && translation) {
			return (
				<PlankHouseBlock
					theme={activeTheme}
					brand={activeBrand}
					plankWalls={getInvoiceAgreementOptions(userData, translation, selectedAccount)}
				/>
			);
		}
	}, [activeBrand, activeTheme, userData, selectedAccount, translation]);

	// ************************************
	// Render
	// ************************************

	return (
		<ApplicationAccountSelectContext.Provider value={_applicationAccountSelectWrapperContext}>
			{activeAccount && (
				<>
					{_renderAccountSelectHouse}
					{showAccountSelectMenu && useAccountSelectMenu && translation && (
						<PopupCard
							contentTitle={getText('chooseInvoiceAgreementPopupTitle', translation)}
							primaryAction={{
								text: getText('choose', translation),
								onClick: () => {
									setActiveAccount(selectedAccount);
									setShowAccountSelectMenu(false);
								},
							}}
							secondaryAction={{
								text: getText('cancel', translation),
								onClick: () => {
									setShowAccountSelectMenu(false);
								},
							}}
							onClose={() => {
								setShowAccountSelectMenu(false);
							}}
							desktopView={desktopView}
						>
							{_renderHouseBlock}
						</PopupCard>
					)}
				</>
			)}
			{props.children}
		</ApplicationAccountSelectContext.Provider>
	);
};
