import {IHomePageGraphicBlockPalette} from "./HomePageGraphicBlock";

export const GetGraphicPalette = (brand: string | undefined): IHomePageGraphicBlockPalette => {
    switch (brand?.toLowerCase()) {
        case "brand-trondelagkraft":
            return {
                shade1: "#FFF104",
                shade2: "#fdfdfd",
                shade3: "#009D96",
                shade4: "#BAF1EE",
                shade5: "#A5EDEA",
                shade6: "#63C3BF",
                shade7: "#E4F7F6",
                shade8: "#023531",
                shade9: "#1A5954",
                shade10: "#FFFFFF",
                shade11: "#FFFCCA",
                shade12: "#FFC804",
                shade13: "#FFF104",
                shade14: "#FFFCCA",
                shade15: "#FFFCCA"
            }
        case "brand-fjordkraft":
        default:
            return {
                shade1: "#FF5500",
                shade2: "#fff",
                shade3: "#D33E00",
                shade4: "#F5AA81",
                shade5: "#D9D9D9",
                shade6: "#FF712B",
                shade7: "#F8D0BA",
                shade8: "#A82C00",
                shade9: "#7C1A00",
                shade10: "#FFEEE6",
                shade11: "#fd844d",
                shade12: "#A82C00",
                shade13: "#D33E00",
                shade14: "#fd4f00",
                shade15: "#d13e00"
            };

    }
}