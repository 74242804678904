import React from 'react';
import { ContentGrid, IconType, StyleGrid, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library';
import {
	useApplicationAccountSelectContext,
	useApplicationCoreDataContext,
	useApplicationUserEditWrapperContext, useConsentContext,
} from '../../../contexts';
import { getPageContent } from './MyPageHomeData';
import { IPlankHouse, PlankHouseBlock } from '../../../blocks';
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../../Prefabs';
import { getText } from '../../../services';
import { format } from 'date-fns';
import { ActionButton, MSTooltip } from '../../../components';
import { ICustomerEditorInformation } from '../../../modals/CustomerInfoModal/CustomerInfoEditor/CustomerInfoEditor';
import { Constants } from '../../../data';
import { ICustomerDataToEdit } from '../../DatahandlerWrappers/ApplicationUserEditWrapper/ApplicationUserEditWrapper';
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import './MyPageHome.scss';

export interface IMyPageHome extends IDefaultViewProps {
	house: IPlankHouse;
	customerInformation: {
		name: string;
		birthdate: Date;
		customerNumber: string;
		phoneNumber: string;
		email: string;
		homeAddress: string;
	};
}

export const MyPageHome = () => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'invoice-setting-page';
	const { userData } = useApplicationCoreDataContext();
	const { setCustomerDataToEdit } = useApplicationUserEditWrapperContext();
	const { isGuest } = useApplicationGuestsAndHostsContext();
	const {
		setUseAccountSelectMenu,
	} = useApplicationAccountSelectContext();
	const {useNewConsents} = useConsentContext()

	// ************************************
	// Render Functionality
	// ************************************

	const _renderText = (textKey: string, useGetText: boolean, config: IMyPageHome) => {
		return (
			<Text
				{...paragraphTextPrefab()}
				theme={getCounterTheme(config.activeTheme)}
				brand={config.activeBrand}
				weight={!useGetText ? 500 : 400}
				align={'align-left'}
			>
				{config.translations && useGetText ? getText(textKey, config.translations) : textKey}
			</Text>
		);
	};

	type CustomerProperty = 'name' | 'birthday' | 'customerNumber' | 'phone' | 'email' | 'registeredAddress';

	interface IChangeAction {
		type: 'action' | 'none' | 'tooltip';
		property: CustomerProperty;
		tooltip?: string;
	}

	const _renderChangeAction = (config: IChangeAction, page: IMyPageHome) => {
		const { type, property, tooltip } = config;
		const { activeBrand, activeTheme, desktopView } = page;

		switch (type) {
			case 'action':
				return (
					<ActionButton
						template={MS_ButtonTemplate(activeTheme, 'link')}
						action={{
							icon: IconType.Edit,
							onClick: () => {
								setCustomerDataToEdit(_handleOnPropertyClick(property, page));
							},
							text: desktopView ? getText('change', page.translations) : undefined,
							disabled: isGuest
						}}
						padding={'small'}
						iconPlacement="Right"
					/>
				);
			case 'tooltip':
				return (
					<MSTooltip
						brand={activeBrand}
						theme={getCounterTheme(activeTheme)}
						alignment={desktopView ? 'bottom-center' : 'center-left'}
						text={tooltip ?? ''}
					/>
				);
			default:
				return <></>;
		}
	};

	const _handleOnPropertyClick = (property: CustomerProperty, config: IMyPageHome) => {
		let customerEdit: ICustomerEditorInformation = {
			phoneNumber: undefined,
			email: undefined,
		};
		let title: string = '';
		let topText: string = '';
		let bottomText: string = '';

		switch (property) {
			case 'phone':
				customerEdit.phoneNumber = userData?.contactInformation?.phone ?? '';
				title = getText('popupTitlePhone', config.translations);
				topText = getText('popupDescTopPhone', config.translations);
				bottomText = getText('popupDescBottomPhone', config.translations);
				break;
			case 'email':
				customerEdit.email = userData?.contactInformation?.email ?? '';
				title = getText('popupTitleEmail', config.translations);
				topText = getText('popupDescTopEmail', config.translations);
				bottomText = getText('popupDescBottomEmail', config.translations);
				break;
		}

		return {
			title,
			topText,
			bottomText,
			fields: customerEdit,
		} as ICustomerDataToEdit;
	};

	// ************************************
	// Render Functionality
	// ************************************

	interface IRenderDesktopCustomerInfoLine {
		textValue: string;
		textKey: string;
		actionType: 'action' | 'none' | 'tooltip';
		propertyType: CustomerProperty;
		tooltip?: string;
		config: IMyPageHome;
	}

	const _renderDesktopCustomerInfoLine = (data: IRenderDesktopCustomerInfoLine) => {
		const { textKey, textValue, actionType = 'none', propertyType, tooltip, config } = data;

		return (
			<StyleGrid
				className={`${classPrefix}__desktop-customer-info-line`}
				direction='row'
				alignment='center-left'
				boxSizing='border-box'
				wrap={false}
				spaceBetween
			>
				<ContentGrid
					className={`${classPrefix}__desktop-customer-info-line__text`}
					direction='column'
					alignment='center-left'
					boxSizing='border-box'
					wrap={false}
					tagType='section'
				>
					{_renderText(textKey, true, config)}
				</ContentGrid>
				<ContentGrid
					className={`${classPrefix}__desktop-customer-info-line__text`}
					direction='column'
					alignment='center-left'
					boxSizing='border-box'
					wrap={false}
					tagType='section'
				>

					{_renderText(textValue, false, config)}
				</ContentGrid>
				<ContentGrid
					className={`${classPrefix}__desktop-customer-info-line__action`}
					direction='column'
					alignment='center-right'
					boxSizing='border-box'
					wrap={false}
					tagType='nav'
				>
					{_renderChangeAction({
						type: actionType,
						property: propertyType,
						tooltip
					}, config)}
				</ContentGrid>
			</StyleGrid>
		);
	}

	const _renderDesktopView = (config: IMyPageHome) => {
		const { userData } = config.user;

		return (
			<StyleGrid
				className={`${classPrefix}__customer-info`}
				direction="column"
				alignment="top-left"
				gap={2}
				boxSizing='border-box'
			>
				{_renderDesktopCustomerInfoLine({
					textKey: 'fieldName',
					textValue: `${userData?.firstName} ${userData?.lastName}`,
					actionType: 'none',
					propertyType: 'name',
					config
				})}
				{_renderDesktopCustomerInfoLine({
					textKey: 'fieldBirthdate',
					textValue: userData.birthdate
						? format(new Date(`${userData.birthdate}`), 'dd.MM.yyyy')
						: getText('unknown', config.translations),
					actionType: 'none',
					propertyType: 'birthday',
					config
				})}
				{_renderDesktopCustomerInfoLine({
					textKey: 'fieldCustomerNumber',
					textValue: `${userData.customerId ? userData.customerId : getText('unknown', config.translations)}`,
					actionType: 'none',
					propertyType: 'customerNumber',
					config
				})}
				{_renderDesktopCustomerInfoLine({
					textKey: 'fieldPhone',
					textValue: `${userData.contactInformation?.phone
						? userData.contactInformation?.phone
						: getText('unknown', config.translations)
						}`,
					actionType: 'action',
					propertyType: 'phone',
					config
				})}
				{_renderDesktopCustomerInfoLine({
					textKey: 'fieldEmail',
					textValue: `${userData.contactInformation?.email
						? userData.contactInformation?.email
						: getText('unknown', config.translations)
						}`,
					actionType: 'action',
					propertyType: 'email',
					config
				})}
				{_renderDesktopCustomerInfoLine({
					textKey: 'fieldRegisteredAddress',
					textValue: `${userData.address.streetAddress ? userData.address.streetAddress : getText('unknown', config.translations)}, ${userData?.address.postalCode
						} ${userData?.address?.postalLocation}`,
					actionType: 'tooltip',
					propertyType: 'registeredAddress',
					tooltip: getText('plankRegisteredAddressTooltip', config.translations),
					config
				})}
			</StyleGrid>
		);
	};

	const _renderMobileInfoContent = (textInfo: any, interactive: any) => {
		return (
			<ContentGrid
				className={`${classPrefix}__customer-info__mobile`}
				tagType="section"
				direction="row"
				alignment="center-left"
				spaceBetween
			>
				<ContentGrid
					tagType="section"
					gap={1}
					direction="column"
					alignment="center-left"
				>
					{textInfo}
				</ContentGrid>
				{interactive}
			</ContentGrid>
		);
	};

	const _renderMobileView = (config: IMyPageHome) => {
		const { userData } = config.user;

		return (
			<StyleGrid
				className={`${classPrefix}__customer-info`}
				direction="column"
				alignment="top-left"
				gap={3}
			>
				{_renderMobileInfoContent(
					<>
						{_renderText('fieldName', true, config)}
						{_renderText(`${userData?.firstName} ${userData?.lastName}`, false, config)}
					</>,
					<>
						{_renderChangeAction({
							type: 'none',
							property: 'name',
						}, config)}
					</>
				)}
				{_renderMobileInfoContent(
					<>
						{_renderText('fieldBirthdate', true, config)}
						{_renderText(
							userData.birthdate
								? format(new Date(`${userData.birthdate}`), 'dd.MM.yyyy')
								: getText('unknown', config.translations),
							false,
							config
						)}
					</>,
					<>
						{_renderChangeAction({
							type: 'none',
							property: 'birthday',
						}, config)}
					</>
				)}
				{_renderMobileInfoContent(
					<>
						{_renderText('fieldCustomerNumber', true, config)}
						{_renderText(`${userData.customerId ? userData.customerId : getText('unknown', config.translations)}`, false, config)}
					</>,
					<>
						{_renderChangeAction({
							type: 'none',
							property: 'customerNumber',
						}, config)}
					</>
				)}
				{_renderMobileInfoContent(
					<>
						{_renderText('fieldPhone', true, config)}
						{_renderText(
							`${userData.contactInformation?.phone
								? userData.contactInformation?.phone
								: getText('unknown', config.translations)
							}`,
							false,
							config
						)}
					</>,
					<>
						{_renderChangeAction({
							type: 'action',
							property: 'phone',
						}, config)}
					</>
				)}
				{_renderMobileInfoContent(
					<>
						{_renderText('fieldEmail', true, config)}
						{_renderText(
							`${userData.contactInformation?.email
								? userData.contactInformation?.email
								: getText('unknown', config.translations)
							}`,
							false,
							config
						)}
					</>,
					<>
						{_renderChangeAction({
							type: 'action',
							property: 'email',
						}, config)}
					</>
				)}
				{_renderMobileInfoContent(
					<>
						{_renderText('fieldRegisteredAddress', true, config)}
						{_renderText(
							`${userData.address.streetAddress ? userData.address.streetAddress : getText('unknown', config.translations)
							}, ${userData?.address.postalCode} ${userData?.address?.postalLocation}`,
							false,
							config
						)}
					</>,
					<>
						{_renderChangeAction({
							type: 'tooltip',
							property: 'registeredAddress',
							tooltip: getText('plankRegisteredAddressTooltip', config.translations),
						}, config)}
					</>
				)}
			</StyleGrid>
		);
	};

	// ************************************
	// Render
	// ************************************

	return (
		PageV2({
			setup: {
				pageType: Constants.epiServerPageNames.me.type,
				usesSubPage: true
			},
			renderUpdate: () => {
				setUseAccountSelectMenu(false);
			},
			dependencies: [{useNewConsents}],
			handleData: getPageContent,
			render: (config: IMyPageHome) => {
				const { house, desktopView } = config;

				return (
					<StyleGrid
						className={classPrefix}
						direction="column"
						alignment="top-center"
						gap={8}
					>
						{desktopView && _renderDesktopView(config)}
						{!desktopView && _renderMobileView(config)}
						{house && (
							<PlankHouseBlock
								className={`${classPrefix}__house`}
								{...house}
							/>
						)}
					</StyleGrid>
				)
			}
		})
	);
};