import React from 'react';
import {BrandColors, ContentGrid, getCounterTheme, Text} from '@fjordkraft/fjordkraft.component.library';
import classNames from 'classnames';
import {useApplicationOverlayWrapperContext} from '../../contexts';
import {IHomePageView} from '../../pages';
import { SliderSelector} from '../../components/SliderSelector/SliderSelector';
import {h2TextPrefab, paragraphTextPrefab} from '../../Prefabs';
import {IPlankDropdownItem, PlankDropdown} from '../../components';
import {HomePageGraphicBlock} from '../HomePageGraphicBlock/HomePageGraphicBlock';
import {ICustomerInstallation, IGuestRelationship} from '../../models';
import {createString, getText} from '../../services';
import './HomePageBannerBlock.scss';
import { ISliderItemData } from '../../components/SliderSelector/SliderItem';

export interface IHomePageBannerBlock extends IHomePageView {
	className: string;
}

export const HomePageBannerBlock = (props: IHomePageBannerBlock) => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'home-page-banner-block';
	const { activeBrand, activeTheme, desktopView, className, translations, user, relationship } = props;
	const { setHostIdForCustomerDataRequests, setChosenHost, chosenHost, hosts, isGuest } = relationship;
	const { setInstallation, userData, installation } = user;
	const { setGlobalLoading } = useApplicationOverlayWrapperContext();

	// ************************************
	// Helper Functionality
	// ************************************

	const _getTitle = (userData: IGuestRelationship): string => {
		if (userData) {
			return createString(getText('pageTitleExtended', translations), {
				name: `${userData.firstName.split(' ')[0]}`,
			});
		} else {
			return '';
		}
	};

	const _getPreActiveItem = (): IPlankDropdownItem | undefined => {
		if (chosenHost) {
			return {
				activeTitle: isGuest
					? `${getText('guest', translations)} ${chosenHost.firstName.split(' ')[0]}`
					: _getTitle(chosenHost),
				left: {
					title: `${chosenHost.firstName.split(' ')[0]}`,
				},
				value: chosenHost,
			} as IPlankDropdownItem;
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _getInstallationSelection = (activeInstallation?: ICustomerInstallation | null) => {
		let selection: any[] = [];

		if (userData) {
			userData.installations.forEach((installation: ICustomerInstallation) => {
				selection.push({
					children: (
						<ContentGrid
							alignment={'center'}
							direction={'row'}
							wrap={false}
							gap={2}
							tagType="section"
						>
							<Text
								className={`${classPrefix}__slider-selector-text`}
								type={'label'}
								weight={500}
								size={'regular'}
								brand={activeBrand}
								theme={getCounterTheme(activeTheme)}
								wrap={'nowrap'}
								faded={installation !== activeInstallation}
							>
								{installation.address.streetAddress}
							</Text>
						</ContentGrid>
					),
					value: installation,
				} as ISliderItemData);
			});
		}

		return selection;
	};

	const _renderAddressSlider = () => {
		if (userData?.installations && userData.installations.length > 1) {
			return (
				<SliderSelector
					uniqueId={`${classPrefix}__slider-selector`}
					data={_getInstallationSelection(installation)}
					defaultOption={installation}
					slideToSelect={false}
					width={550}
					mobileWidth={document.body.clientWidth * 0.9}
					onValueChange={(value) => {
						if (value) {
							setInstallation(value);
						}
					}}
				/>
			);
		} else if (userData?.installations[0]) {
			return (
				<Text
					className={`${classPrefix}__top__address`}
					{...paragraphTextPrefab()}
					weight={500}
					theme={getCounterTheme(activeTheme)}
					wrap={'nowrap'}
					brand={activeBrand}
				>
					{userData.installations[0].address.streetAddress}
				</Text>
			);
		}
	};

	const _renderMainTitle = (config: IHomePageView) => {
		const { sub, guestDropdown, user, desktopView } = config;
		const { mainUser } = user;

		if (guestDropdown) {
			return (
				<PlankDropdown
					{...guestDropdown}
					id={`${classPrefix}-dropdown-userData-select`}
					className={`${classPrefix}__dropdown-userData-select`}
					theme={activeTheme}
					brand={activeBrand}
					genericDropdownStyle={true}
					gridPlacement={!desktopView && hosts?.length === 0 ? 'bottom-left' : 'bottom-center'}
					preActiveItem={_getPreActiveItem()}
					onSelectedChange={(value: any) => {
						const chosenUser = value as IGuestRelationship;
						setGlobalLoading(true);

						if (mainUser && mainUser.customerId !== chosenUser.customerId) {
							setChosenHost(chosenUser);
							setHostIdForCustomerDataRequests(chosenUser.customerId);
						} else {
							setChosenHost(undefined);
							setHostIdForCustomerDataRequests(undefined);
						}
					}}
					customization={{
						text: {
							type: 'label',
							weight: 700,
							size: desktopView ? 'huge' : 'large',
						},
						iconBoxed: true,
						gap: 2,
						gapType: 'px',
					}}
				/>
			);
		} else {
			return (
				<Text
					className={`${classPrefix}__top__title`}
					{...h2TextPrefab()}
					weight={700}
					size={desktopView ? 'huge' : 'large'}
					color={BrandColors['primary-shade-light-2']}
				>
					{sub?.title}
				</Text>
			);
		}
	};

	// ************************************
	// Render
	// ************************************

	return (
		<ContentGrid
			className={classNames(`${classPrefix}`, {
				[`${className}`]: className,
			})}
			direction="column"
			alignment={!desktopView && hosts?.length === 0 ? 'top-left' : 'top-center'}
			gap={2}
			tagType={'section'}
		>
			{props && _renderMainTitle(props)}
			{userData?.installations && _renderAddressSlider()}
			<HomePageGraphicBlock className={`${classPrefix}__graphic`} brand={activeBrand} />
		</ContentGrid>
	);
};
