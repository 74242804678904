import React, { useMemo, useState } from 'react';
import { IServiceBasePageView, ServiceBasePage } from './ServiceBasePage';
import {
	IServiceManagementPopup,
	IServiceOrderOrCancel,
	ServiceManagementPopup,
	ServiceOrderAndCancelSteppes,
} from '../../../modals';
import { IServiceBasePageData, getServiceBasePageData } from './ServiceBasePageData';
import { useAddonServicesContext, useApplicationContext } from '../../../contexts';
import { useSearchParams } from 'react-router-dom';
import { ServiceStatus } from '../../../models';
import { getCustomerInstallationStatusData } from './Datahandling/UniqueServices';
import { getSpecificAddonStateForInstallation } from '../../../services';

export const ServicePage = () => {
	// ************************************
	// Properties
	// ************************************

	const { activeBrand, activeTheme } = useApplicationContext();
	const { updateAddonStates } = useAddonServicesContext();

	// ************************************
	// Lifecycle
	// ************************************

	const [popupData, setPopupData] = useState<IServiceManagementPopup>();
	const [queryParams, setQueryParams] = useSearchParams();

	// ************************************
	// Data handling
	// ************************************

	// Function: Handles data based on various properties and parses into view data
	const _handlePageData = async (config: IServiceBasePageData) => {
		let viewData: IServiceBasePageView | undefined = await getServiceBasePageData({
			...config,
			...{
				onClickServiceHandling: (value: IServiceOrderOrCancel) => {
					setPopupData(value);
				},
			},
		});

		if (viewData) {
			await _handleQueryParamStatus(viewData, config);
		}

		return viewData;
	};

	// Function: If returning from or has set parameters, popup will appear
	const _handleQueryParamStatus = async (viewData: IServiceBasePageView, config: IServiceBasePageData) => {
		let queryParamState = queryParams?.get('orderStatus') as ServiceOrderAndCancelSteppes | undefined;

		if (queryParamState && viewData) {
			const { translations, user, account } = config;
			const { userData, installation } = user;
			const status: ServiceStatus = _getStatusBasedOnOrderMethodType(config);

			if (queryParamState == 'SUCCESS') queryParamState = 'REDIRECT_SUCCESS';

			setPopupData({
				status,
				installation,
				userData,
				account,
				page: translations,
				predefinedStatusStep: queryParamState,
			});
		}
	};

	// ************************************
	// Helpers
	// ************************************

	const _getStatusBasedOnOrderMethodType = (config: IServiceBasePageData): ServiceStatus => {
		const { translations } = config;

		let status: ServiceStatus = 'INACTIVE';

		switch (translations.orderMethodType) {
			case 'customer':
				status = _customerStatus(config);
				break;
			case 'installation':
				status = _installationStatus(config);
				break;
			case 'invoiceAgreement':
				status = _accountStatus(config);
				break;
		}

		return status;
	};

	const _customerStatus = (config: IServiceBasePageData): ServiceStatus => {
		if (config.user.userData) {
			return getCustomerInstallationStatusData(config).status;
		}
		return 'INACTIVE';
	};

	const _installationStatus = (config: IServiceBasePageData): ServiceStatus => {
		const { translations, user, addonStates } = config;
		const { installation } = user;

		if (installation && addonStates) {
			return (
				getSpecificAddonStateForInstallation(
					translations.productDefinitionId,
					installation.meterId,
					addonStates
				)?.state ?? 'INACTIVE'
			);
		}

		return 'INACTIVE';
	};

	const _accountStatus = (config: IServiceBasePageData): ServiceStatus => {
		const { account } = config;
		return account?.steddiInfo ? 'ACTIVE' : 'INACTIVE';
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderServiceManagementPopup = useMemo(() => {
		if (popupData) {
			return (
				<ServiceManagementPopup
					{...popupData}
					brand={activeBrand}
					theme={activeTheme}
					onClose={() => {
						setQueryParams(undefined);
						setPopupData(undefined);
						updateAddonStates();
					}}
				/>
			);
		}
	}, [popupData, activeBrand, activeTheme]);

	// ************************************
	// Render
	// ************************************

	return (
		<>
			<ServiceBasePage handleData={_handlePageData} />
			{_renderServiceManagementPopup}
		</>
	);
};
