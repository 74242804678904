import React, { useState } from 'react';
import {
    getCounterTheme,
    Text,
    BrandColors,
    Icon,
    IconType,
    StyleGrid,
    ContentGrid,
    CallState,
} from '@fjordkraft/fjordkraft.component.library';
import {
    useApplicationServicehandlerContext,
    useDefaultPageContext,
    useSubPageLayoutContext,
} from '../../../../contexts';
import { getPageContent } from './UsePointsInvoicePageData';
import { ReactComponent as InvoiceSvg } from '../../../../assets/art/benefits/usePoints-invoice.svg';
import { ReactComponent as ConfirmedSvg } from '../../../../assets/art/benefits/usePoints-invoice-confirmed.svg';
import { createString, getText, tNumber } from '../../../../services';
import { Constants } from '../../../../data';
import { Card, MSRichText, MsButton } from '../../../../components';
import { MS_ButtonTemplate, h4TextPrefab, paragraphTextPrefab, smallParagraphTextPrefab } from '../../../../Prefabs';
import { postKickbackDeduction } from '../../../../services/collection/BenefitService';
import { IDefaultViewProps, PageV2 } from '../../../PageV2';
import './UsePointsInvoicePage.scss';

export interface IUsePointsInvoicePage extends IDefaultViewProps {
    availablePoints: number;
}

export const UsePointsInvoicePage = () => {
    // ************************************
    // Properties
    // ************************************

    const { setBack } = useSubPageLayoutContext();
    const { setContentLoading } = useDefaultPageContext();
    const { POST } = useApplicationServicehandlerContext();
    const classPrefix = 'use-points-invoice-page';

    // ************************************
    // Lifecycle
    // ************************************

    const [pointsToDeduct, setPointsToDeduct] = useState<number>();
    const [step, setStep] = useState<number>(0);
    const [deductionResult, setDeductionResult] = useState<CallState>();
    const [validInput, setValidInput] = useState<boolean>(false);
    const [maxPointsError, setMaxPointsError] = useState<boolean>(false);

    // ************************************
    // Helper Functions
    // ************************************

    const _deductPoints = async (availablePoints: number, points: number | undefined) => {
        if (points && availablePoints) {
            if (points <= availablePoints && points > 0) {
                setContentLoading(true);
                setBack(undefined);
                let resp = await postKickbackDeduction(POST, points);
                setStep(2);
                setContentLoading(false);
                setDeductionResult(resp.callState);
            }
        }
    };

    const _validatePoints = (availablePoints: number, points: number | undefined) => {
        if (points && availablePoints) {
            if (points > 0 && points <= availablePoints) {
                if (points > 3000) {
                    setMaxPointsError(true);
                    setValidInput(false);
                } else {
                    setMaxPointsError(false);
                    setValidInput(true);
                    setPointsToDeduct(points);
                }
            } else {
                setValidInput(false);
            }
        } else {
            setValidInput(false);
        }
    };

    // ************************************
    // Render Functionality
    // ************************************

    const _arrowIcon = (direction: 'left' | 'right', config: IUsePointsInvoicePage) => {
        const { activeBrand, activeTheme } = config;

        return (
            <Icon
                className={`${classPrefix}__card__content__button__icon--${direction}`}
                brand={activeBrand}
                theme={activeTheme}
                type={
                    direction === 'right'
                        ? IconType.ArrowRight
                        : IconType.ArrowLeft
                }
                width={0.8}
                height={0.8}
                color={BrandColors['text-shade-light-1']}
            />
        );
    };

    const _renderStepIcon = (config: IUsePointsInvoicePage) => {
        return (
            <>
                {step !== 2 ? (
                    <InvoiceSvg width={100} height={100} />
                ) : deductionResult === 'success' ? (
                    <ConfirmedSvg width={100} height={100} />
                ) : (
                    <Icon
                        brand={config.activeBrand}
                        type={IconType.ConfusedFace}
                        color={BrandColors['primary-shade-light-2']}
                        width={7}
                        height={7}
                    />
                )}
            </>
        );
    }

    const _renderStep0 = (config: IUsePointsInvoicePage) => {
        const { activeBrand, activeTheme, translations, availablePoints } = config;

        if (step === 0) {
            return (
                <>
                    <MSRichText
                        brand={activeBrand}
                        theme={getCounterTheme(activeTheme)}
                        text={createString(
                            getText(
                                'onInvoicePointsAvailable',
                                translations
                            ),
                            {
                                amount: tNumber(
                                    availablePoints ?? 0,
                                    'no-NO',
                                    0,
                                    0
                                ),
                            }
                        )}
                    />
                    <ContentGrid
                        alignment={'center'}
                        direction={'column'}
                        gap={2}
                        tagType={'section'}
                    >
                        <Text
                            {...paragraphTextPrefab()}
                            brand={activeBrand}
                            theme={getCounterTheme(activeTheme)}
                            align={'align-center'}
                        >
                            {getText(
                                'onInvoiceHowManyPoints',
                                translations
                            )}
                        </Text>

                        <Text
                            {...smallParagraphTextPrefab()}
                            className={`${classPrefix}__card__content__error`}
                            color={
                                BrandColors['status-shade-light-3']
                            }
                            align={'align-center'}
                        >
                            {maxPointsError
                                ? getText(
                                    'onInvoiceMaxPointsError',
                                    translations
                                )
                                : ''}
                        </Text>

                        <ContentGrid
                            className={`${classPrefix}__card__content__input`}
                            alignment={'center'}
                            direction={'row'}
                            tagType={'nav'}
                            style={{
                                borderColor: validInput
                                    ? 'green'
                                    : 'red',
                            }}
                        >
                            <input
                                type={'number'}
                                step={1}
                                onChange={(e) => {
                                    // Forces input to be an integer:
                                    let input = Math.floor(
                                        e.target.valueAsNumber
                                    );
                                    e.target.valueAsNumber =
                                        input;
                                    _validatePoints(availablePoints, input);
                                }}
                            ></input>
                            <Text
                                {...paragraphTextPrefab()}
                                brand={activeBrand}
                                theme={getCounterTheme(
                                    activeTheme
                                )}
                                faded
                            >
                                {getText(
                                    'onInvoicePoengInput',
                                    translations
                                )}
                            </Text>
                        </ContentGrid>
                    </ContentGrid>
                </>
            );
        }
    }

    const _renderStep1 = (config: IUsePointsInvoicePage) => {
        const { activeBrand, activeTheme, translations, availablePoints } = config;

        if (step === 1 && availablePoints && pointsToDeduct) {
            if (availablePoints !== null && pointsToDeduct !== undefined) {
                return (
                    <>
                        <MSRichText
                            brand={activeBrand}
                            theme={getCounterTheme(activeTheme)}
                            text={createString(
                                getText(
                                    'onInvoicePointsChosenToUse',
                                    translations
                                ),
                                {
                                    amount: tNumber(
                                        pointsToDeduct,
                                        'no-NO',
                                        0,
                                        0
                                    ),
                                }
                            )}
                        />
                        <Text
                            type={'p'}
                            brand={activeBrand}
                            theme={getCounterTheme(activeTheme)}
                        >
                            {getText('onInvoiceAmountAfterUse', translations)}{' '}
                            <span id="bold-green-text">
                                {tNumber(availablePoints - pointsToDeduct, 'no-NO', 0, 2)}{' '}
                                {getText('onInvoicePoengInput', translations)}
                            </span>
                        </Text>
                    </>
                );
            }
        }
    }

    const _renderStep2 = (config: IUsePointsInvoicePage) => {
        const { activeBrand, activeTheme, translations } = config;

        if (step === 2) {
            return (
                <>
                    {deductionResult === 'success' ? (
                        <>
                            <Text
                                type={'p'}
                                align={'align-center'}
                                brand={activeBrand}
                                theme={getCounterTheme(
                                    activeTheme
                                )}
                            >
                                {getText(
                                    'onInvoiceSuccessText1',
                                    translations
                                )}
                            </Text>
                            <Text
                                type={'p'}
                                align={'align-center'}
                                brand={activeBrand}
                                theme={getCounterTheme(
                                    activeTheme
                                )}
                            >
                                {getText(
                                    'onInvoiceSuccessText2',
                                    translations
                                )}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Text
                                type={'p'}
                                align={'align-center'}
                                brand={activeBrand}
                                theme={getCounterTheme(
                                    activeTheme
                                )}
                            >
                                {getText(
                                    'onInvoiceErrorText1',
                                    translations
                                )}
                            </Text>
                            <Text
                                type={'p'}
                                align={'align-center'}
                                brand={activeBrand}
                                theme={getCounterTheme(
                                    activeTheme
                                )}
                            >
                                {getText(
                                    'onInvoiceErrorText2',
                                    translations
                                )}
                            </Text>
                        </>
                    )}
                </>
            );
        }
    }

    // ************************************
    // Render
    // ************************************

    return (
        PageV2({
            setup: {
                pageType: Constants.epiServerPageNames.benefits.type,
                usesSubPage: true
            },
            dependencies: [
                { step },
                { validInput }
            ],
            handleData: getPageContent,
            render: (config: IUsePointsInvoicePage) => {
                const { activeBrand, activeTheme, translations, availablePoints } = config;

                return (
                    <StyleGrid
                        className={`${classPrefix}`}
                        alignment={'center-left'}
                        direction={'row'}
                        brand={activeBrand}
                        theme={getCounterTheme(activeTheme)}
                    >
                        <Card
                            className={`${classPrefix}__card`}
                            brand={activeBrand}
                            theme={activeTheme}
                            alignment={'center'}
                        >
                            <ContentGrid
                                className={`${classPrefix}__card__content`}
                                alignment={'top-center'}
                                direction={'column'}
                                gap={4}
                                tagType={'nav'}
                            >
                                <Text
                                    {...h4TextPrefab()}
                                    weight={700}
                                    brand={activeBrand}
                                    theme={getCounterTheme(activeTheme)}
                                    align={'align-center'}
                                >
                                    {step !== 2
                                        ? getText('onInvoiceHeading', translations)
                                        : deductionResult !== 'success'
                                            ? getText('onInvoiceHeadingError', translations)
                                            : getText('onInvoiceHeadingSuccess', translations)}
                                </Text>

                                {_renderStepIcon(config)}
                                {_renderStep0(config)}
                                {_renderStep1(config)}
                                {_renderStep2(config)}

                                <MsButton
                                    className={`${classPrefix}__card__content__button`}
                                    template={MS_ButtonTemplate(
                                        activeTheme,
                                        'primary'
                                    )}
                                    brand={activeBrand}
                                    theme={activeTheme}
                                    disabled={!validInput}
                                    action={
                                        step < 2
                                            ? {
                                                onClick: () => {
                                                    if (step === 0) setStep(1);
                                                    if (step === 1)
                                                        _deductPoints(
                                                            availablePoints,
                                                            pointsToDeduct,
                                                        );
                                                },
                                            }
                                            : {
                                                link: Constants.paths
                                                    .benefitsPage,
                                            }
                                    }
                                >
                                    {step === 2 && _arrowIcon('left', config)}
                                    <Text
                                        {...paragraphTextPrefab()}
                                        brand={activeBrand}
                                        theme={activeTheme}
                                    >
                                        {step === 0 &&
                                            getText('onInvoiceNextButton', translations)}
                                        {step === 1 &&
                                            getText('onInvoiceConfirmButton', translations)}
                                        {step === 2 &&
                                            getText('onInvoiceBackButton', translations)}
                                    </Text>
                                    {step < 2 && _arrowIcon('right', config)}
                                </MsButton>
                            </ContentGrid>
                        </Card>
                    </StyleGrid>
                )
            }
        })
    )
};
