import {
	BrandColors,
	IComponentTemplate,
	IComponentTransform,
	IThemedColors,
	Theme,
} from '@fjordkraft/fjordkraft.component.library';

const _transform = {
	width: '100%',
	height: 'auto',
	padding: '1rem',
	border: {
		radiusType: 'curved',
	},
	grid: {
		boxSizing: 'border-box',
		direction: 'column',
		tagType: 'div',
		gap: 2,
	},
} as IComponentTransform;

const _palette = {
	Light: {
		background: { color: BrandColors['tertiary-shade-light-1'] },
		text: { color: BrandColors['text-shade-dark-3'] },
		icon: { color: BrandColors['icon-shade-dark-2'] },
	},
	Dark: {
		background: { color: BrandColors['tertiary-shade-light-1'] },
		text: { color: BrandColors['text-shade-dark-3'] },
		icon: { color: BrandColors['icon-shade-dark-2'] },
	},
} as IThemedColors;

export const CommercialTemplate = (theme: Theme) => {
	return {
		transform: _transform,
		palette: _palette[theme],
	} as IComponentTemplate;
};
