import React from 'react';
import {
    Text,
    IMasterPlank,
    IconType,
    ITextCustomization,
    IIconAdvanced,
    Icon,
    IComponentTemplate,
    ContentGrid,
    getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import { ActionButton, MSRichText } from '../../../components';
import "../PlankPrefabStyle.scss";
import { h4TextPrefab } from '../../Texts/Header4TextPrefab';
import { paragraphTextPrefab } from '../../Texts/ParagraphTextPrefab';

export interface IActionPlankPrefabCustomization {
    title?: ITextCustomization;
    description?: ITextCustomization;
    icon?: IIconAdvanced;
}

export interface IActionPlankPrefab extends IMasterPlank {
    left?: {
        title?: string;
        description?: string;
        icon?: IconType;
        customization?: IActionPlankPrefabCustomization;
    };
    right: {
        template: IComponentTemplate;
    };
    bottom?: {
        title?: string;
        description?: string;
        customization?: {
            description: {
                align: "align-left" | "align-center" | "align-right" | undefined
            }
        }
    }
    actionIconPlacement?: 'Left' | 'Right';
    actionButtonPadding?: 'none' | 'default' | 'small' | 'medium' | 'large';
    desktopView?: boolean;
}

export const ActionPlankPrefab = (props: IActionPlankPrefab) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme = 'Light',
        brand,
        left,
        action,
        right,
        bottom,
        actionIconPlacement,
        actionButtonPadding = 'small',
        desktopView = true,
        template
    } = props;
    const classPrefix = 'ms-action-plank';

    // ************************************
    // Render Functionality
    // ************************************

    const _renderBottomContent = () => {
        if (bottom) {
            return (
                <ContentGrid
                    tagType='section'
                    alignment='top-left'
                    direction='column'
                    boxSizing='border-box'
                    gap={1}
                >
                    {bottom.title && (
                        <Text
                            brand={brand}
                            weight={500}
                            size={'regular'}
                            family={'main'}
                            type={'h4'}
                            theme={getCounterTheme(theme)}
                            align={'align-right'}
                        >
                            {bottom.title}
                        </Text>
                    )}
                    {bottom.description && (
                        <MSRichText
                            brand={brand}
                            text={bottom.description}
                            theme={getCounterTheme(theme)}
                            alignment='lawful'
                        />
                    )}
                </ContentGrid>
            );
        }
    }

    const _renderLeftIcon = () => {
        if (left?.icon) {
            return (
                [
                    <Icon
                        brand={brand}
                        type={left.icon}
                        width={left.customization?.icon?.size ?? 1.5}
                        height={left.customization?.icon?.size ?? 1.5}
                        {...left.customization?.icon}
                    />
                ]
            );
        }
    }

    const _renderLeftTitle = () => {
        if (left?.title) {
            return (
                <Text
                    {...h4TextPrefab()}
                    brand={brand}
                    align={'align-left'}
                    {...left.customization?.title}
                >
                    {left.title}
                </Text>
            );
        }
    }

    const _renderLeftDescription = () => {
        if (left?.description) {
            return (
                <MSRichText
                    {...paragraphTextPrefab({ theme: theme })}
                    text={left.description}
                    brand={brand}
                    alignment="lawful"
                    customization={{
                        type: 'p',
                        faded: true,
                        align: 'align-left',
                        wrap: 'pre-wrap',
                    }}
                    {...left.customization?.description}
                />
            );
        }
    }

    const _renderLeft = () => {
        if (left?.title || left?.description) {
            let elements: any = [];

            elements.push(_renderLeftTitle());
            elements.push(_renderLeftDescription());

            if (!desktopView) {
                elements = elements.concat(_renderAction());
            }

            return elements;
        }
    }

    const _renderAction = () => {
        if (action) {
            return (
                [
                    <ActionButton
                        key={uuid4()}
                        className={`${classPrefix}__action`}
                        action={action}
                        template={right.template}
                        brand={brand}
                        padding={
                            actionButtonPadding === 'none'
                                ? undefined
                                : actionButtonPadding
                        }
                        iconPlacement={actionIconPlacement ?? 'Left'}
                    />
                ]
            );
        }
    }

    // ************************************
    // Render
    // ************************************

    return {
        id,
        className: classNames(classPrefix, {
            [`${className}`]: className
        }),
        brand,
        template,
        leftColumn1: _renderLeftIcon(),
        leftColumn2: _renderLeft(),
        rightColumn2: desktopView ? _renderAction() : undefined,
        bottomContent: _renderBottomContent(),
        action: undefined,
    } as IMasterPlank;
};
