import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { IMSPlankWall, IPlankHouse } from '../../../../blocks';
import { IServiceBasePageData } from '../ServiceBasePageData';
import { IStatePlank } from '../../../../components';
import { ICustomerAccountInformation, IServicePage, ServiceStatus } from '../../../../models';
import { ITextPlankPrefab, MS_ButtonTemplate, getPlankPrefab } from '../../../../Prefabs';
import { IActionPlankPrefab } from '../../../../Prefabs/Planks/ActionPlankPrefab/ActionPlankPrefab';
import {
	showingDropdownOrder,
	createString,
	getAddressesBasedOnAgreement,
	getServiceStatusColor,
	getSpecificAddonStateForInstallation,
	getText,
} from '../../../../services';
import { getPredictablePaymentPlanks, getTrumfPlankWalls } from './UniqueServices';
import { IServiceOrderOrCancel } from '../../../../modals';

// ************************************
// PUBLIC
// ************************************

export const getServicePageHouseData = async (config: IServiceBasePageData): Promise<IPlankHouse | undefined> => {
	const { translations } = config;

	let house: IPlankHouse = {
		plankWalls: [],
	};

	switch (translations.orderMethodType) {
		case 'installation':
			house.plankWalls = house.plankWalls.concat(_getInstallationWalls(config));
			break;
		case 'invoiceAgreement':
			house.plankWalls = house.plankWalls.concat(_getInvoiceAgreementWalls(config));
			break;
		case 'customer':
			house.plankWalls = house.plankWalls.concat(await _getCustomerWalls(config));
			break;
	}

	return house;
};

// ************************************
// PRIVATE :: Walls
// ************************************

const _getInstallationWalls = (config: IServiceBasePageData): IMSPlankWall[] => {
	const { installation } = config.user;

	let walls: IMSPlankWall[] = [];
	let planks: IStatePlank[] | undefined = _getInstallationPlanks(config);

	if (installation && planks && planks.length > 0) {
		walls.push({ planks });
	}

	return walls;
};

const _getInvoiceAgreementWalls = (config: IServiceBasePageData): IMSPlankWall[] => {
	const { user, translations } = config;
	const { userData } = user;

	let walls: IMSPlankWall[] = [];

	if (userData.accounts?.length > 0) {
		walls.push({ planks: _getAccountPlanks(config) });
	}

	// For handling Steddi
	if (translations.servicePageId === 'forutsigbar') {
		let steddiWalls: IMSPlankWall[] | undefined = _getSteddiPlanks(config);

		if (steddiWalls) {
			walls = walls.concat(steddiWalls);
		}
	}

	return walls;
};

const _getCustomerWalls = async (config: IServiceBasePageData): Promise<IMSPlankWall[]> => {
	const { translations } = config;

	let walls: IMSPlankWall[] = [];

	if (translations.servicePageId === 'trumf') {
		let trumfWalls: IMSPlankWall[] | undefined = await getTrumfPlankWalls(config);

		if (trumfWalls) {
			walls = walls.concat(trumfWalls);
		}
	}

	return walls;
};

// ************************************
// PRIVATE :: Planks
// ************************************

const _getInstallationPlanks = (config: IServiceBasePageData): IStatePlank[] | undefined => {
	const { translations, addonStates, desktopView, onClickServiceHandling, user, relationship } = config;
	const { installation } = user;
	const { isGuest } = relationship;

	if (installation) {
		let planks: IStatePlank[] = [];
		let status: ServiceStatus =
			getSpecificAddonStateForInstallation(translations.productDefinitionId, installation.meterId, addonStates)
				?.state ?? 'INACTIVE';
		let title: string = installation.address.streetAddress ?? '';
		let desc: string = createString(getText('plankInstallationDesc', translations), {
			meterId: installation.meterId,
		});

		if (!showingDropdownOrder(config, status)) {
			planks.push(
				_getActionPlank({
					status,
					title,
					desc,
					servicePage: translations,
					desktopView,
					isGuest,
					onClick: () => {
						onClickServiceHandling({
							installation,
							status,
							page: translations,
							addonStateResponse: addonStates,
						});
					},
				})
			);
		}

		if (planks.length > 0) {
			return planks;
		}
	}
};

const _getAccountPlanks = (config: IServiceBasePageData): IStatePlank[] => {
	const { user, translations, desktopView, onClickServiceHandling, relationship } = config;
	const { userData } = user;
	const { isGuest } = relationship;

	let planks: IStatePlank[] = [];

	if (userData && translations) {
		userData.accounts.forEach((account: ICustomerAccountInformation) => {
			let desc: string = getAddressesBasedOnAgreement({ userData, accountId: account.accountId });
			let title: string = createString(getText('plankAccountTitle', translations), {
				accountNumber: account.accountId,
			});
			let status: ServiceStatus = account.steddiInfo ? 'ACTIVE' : 'INACTIVE';

			const actionPlank = _getActionPlank({
				title,
				desc,
				servicePage: translations,
				status,
				desktopView,
				isGuest,
				onClick: () => {
					onClickServiceHandling({
						account,
						page: translations,
						status,
					} as IServiceOrderOrCancel);
				},
			});

			if (actionPlank) planks.push(actionPlank);
		});
	}

	return planks;
};

const _getSteddiPlanks = (config: IServiceBasePageData): IMSPlankWall[] | undefined => {
	return getPredictablePaymentPlanks(config);
};

// ************************************
// PRIVATE :: Handling
// ************************************

interface IServiceActionPlank {
	title: string;
	desc: string;
	servicePage: IServicePage;
	status: ServiceStatus;
	desktopView: boolean;
	isGuest: boolean;
	onClick: (value: any) => void;
}

const _getActionPlank = (config: IServiceActionPlank): IStatePlank => {
	const { title, desc, servicePage, status, onClick, desktopView, isGuest } = config;

	let statusValues = _getActionPlankStatusValues(status, servicePage);
	let active: boolean = status === 'ACTIVE' || status === 'ACTIVE_FUTURE';

	if (statusValues.showButton) {
		return getPlankPrefab('Action', {
			action: {
				disabled: isGuest,
				onClick,
				text: desktopView ? statusValues.value : undefined,
				icon: _getActionPlankIcon(desktopView, active),
			},
			actionButtonPadding: desktopView ? 'small' : 'default',
			left: {
				title,
				description: desc,
			},
			right: {
				template: MS_ButtonTemplate('Light', active ? 'secondary' : 'primary'),
			},
			actionIconPlacement: 'Right',
		} as IActionPlankPrefab);
	} else {
		return getPlankPrefab('Text', {
			left: {
				title,
				description: desc,
			},
			right: servicePage.allowOrder
				? {
						title: statusValues.value,
						customization: {
							title: {
								color: getServiceStatusColor(status),
							},
						},
				  }
				: undefined,
		} as ITextPlankPrefab);
	}
};

const _getActionPlankIcon = (desktopView: boolean, active: boolean): IconType => {
	if (desktopView) {
		return IconType.ExternalLinkThick;
	} else {
		return active ? IconType.MinusThin : IconType.PlusThin;
	}
};

const _getActionPlankStatusValues = (status: ServiceStatus, servicePage: IServicePage) => {
	let showButton: boolean = false;
	let value: string = '';

	switch (status) {
		case 'ACTIVE':
		case 'ACTIVE_FUTURE':
			value = getText('cancel', servicePage);
			showButton = true;
			break;
		case 'INACTIVE':
		case 'ORDER_CANCELLED_BY_CUSTOMER':
		case 'TERMINATED':
		case 'ORDER_FAILED':
			value = getText('order', servicePage);
			showButton = servicePage.allowOrder ?? true;
			break;
		case 'ACTIVATING':
		case 'TERMINATING':
		case 'ORDER_IN_PROGRESS':
		case 'ORDER_WAITING_FOR_CUSTOMER':
			value = getText('awaiting', servicePage);
			break;
	}

	return { showButton, value };
};
