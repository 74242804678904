import React, { useEffect, useMemo, useState } from 'react';
import { IComponent, ContentGrid, IconType, StyleGrid, IAction } from '@fjordkraft/fjordkraft.component.library';
import { v4 as uuid4 } from 'uuid';
import { useApplicationContext, useApplicationCoreDataContext, useDefaultPageContext } from '../../contexts';
import {
	ActionButton,
	AsyncRedirectButton,
	HeaderLogoButton,
	IMobileSelectSection,
	LogoutButton,
	MenuButton,
	MobileSelectMenu,
} from '../../components';
import { ICustomerInstallation, IHomePage } from '../../models';
import { IsActiveCustomer, getText, isExternalUrl } from '../../services';
import { Constants } from '../../data';
import { ListedButtonHighlightedTemplate, ListedButtonTemplate, MS_ButtonTemplate } from '../../Prefabs';
import { useNavigate } from 'react-router';
import './Header.scss';

export interface IHeader extends IComponent {
	rootPageData: IHomePage;
	navigating: (path: string) => void;
}

export const Header = (props: IHeader) => {
	// ************************************
	// Properties
	// ************************************

	const { activeBrand, activeTheme, desktopView } = useApplicationContext();
	const { rootPageData, navigating } = props;
	const navigate = useNavigate();
	const { userData, installation, translation } = useApplicationCoreDataContext();
	const classPrefix = 'header';

	// ************************************
	// Lifecycle
	// ************************************

	const [activeNavigationElementId, setActiveNavigationElementId] = useState<string>();
	const [toggleMobileMenu, setToggleMobileMenu] = useState<boolean>(false);
	const [goToMobileText, setGoToMobileText] = useState<string>('');

	useEffect(() => {
		if (translation?.pageNavigationFocus && activeNavigationElementId !== translation?.pageNavigationFocus) {
			setActiveNavigationElementId(translation.pageNavigationFocus);
		}
	}, [translation]);

	useEffect(() => {
		if (userData) {
			if (userData.isMobileCustomer) {
				setGoToMobileText(getText('goToMobileLinkTextCustomer', rootPageData));
			} else {
				setGoToMobileText(getText('goToMobileLinkTextNonCustomer', rootPageData));
			}
		}
	}, [userData]);

	// ************************************
	// Helpers
	// ************************************

	const _allowMenuButton = (data: any, inst?: ICustomerInstallation) => {
		if (!IsActiveCustomer(inst)) {
			if (data.blockId === 'Benefits') {
				return false;
			}
		}

		return true;
	};

	const _getMobileNav = () => {
		let totalLength: number = rootPageData.headerNav.length;

		let sections: IMobileSelectSection[] = [
			{
				items: [],
			},
			{
				items: [],
			},
		];

		if (rootPageData.headerNav.length > 0) {
			rootPageData.headerNav.forEach((data: any, index: number) => {
				let buttonAction: IAction = {
					text: data.text,
					icon: data.iconType,
					active: activeNavigationElementId === data.blockId,
					useRouterLink: !isExternalUrl(data.url),
					overrideTagEnforcer: true,
					onClick: (e) => {
						navigate(data.url ?? '/');
						setToggleMobileMenu(false);
					},
				};

				if (_allowMenuButton({ ...data, ...buttonAction }, installation)) {
					sections[0].items.push(
						<ActionButton
							key={uuid4()}
							customization={{
								icon: {
									width: 1.5,
									height: 1.5,
								},
								text: {
									weight: 600,
									size: 'large',
								},
							}}
							action={buttonAction}
							brand={activeBrand}
							template={ListedButtonTemplate(activeTheme, index, totalLength)}
						/>
					);
				}
			});

			sections[1].items.push(
				<AsyncRedirectButton
					key={uuid4()}
					text={goToMobileText}
					icon={undefined}
					addition={'Redirect/mobile'}
					flipped={true}
					template={ListedButtonHighlightedTemplate(activeTheme, 1, 2)}
					customization={{
						icon: {
							width: 1.5,
							height: 1.5,
						},
						text: {
							weight: 600,
							size: 'large',
						},
					}}
				/>
			);
			sections[1].items.push(
				<LogoutButton
					key={uuid4()}
					theme={activeTheme}
					brand={activeBrand}
					text={getText('logoutText', rootPageData)}
					icon={undefined}
					flipped={true}
					template={ListedButtonHighlightedTemplate(activeTheme, 1, 2)}
					customization={{
						icon: {
							width: 1.5,
							height: 1.5,
						},
						text: {
							weight: 600,
							size: 'large',
						},
					}}
				/>
			);
		}

		return sections;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderHeaderNavigation = useMemo(() => {
		let nav: any[] = [];

		if (rootPageData.headerNav.length > 0) {
			rootPageData.headerNav.forEach((data: any) => {
				let buttonAction: IAction = {
					link: data.url,
					text: data.text,
					icon: data.iconType,
					active: activeNavigationElementId === data.blockId,
					useRouterLink: !isExternalUrl(data.url),
				};

				if (_allowMenuButton({ ...data, ...buttonAction }, installation)) {
					nav.push(
						<MenuButton
							key={uuid4()}
							action={buttonAction}
							navigating={navigating}
						/>
					);
				}
			});
		}

		return nav;
	}, [activeNavigationElementId, rootPageData, installation]);

	const _renderDesktop = () => {
		return (
			<ContentGrid
				className={classPrefix}
				direction="row"
				alignment="center"
				tagType="header"
			>
				<ContentGrid
					className={`${classPrefix}__left`}
					tagType={'nav'}
					direction="row"
					alignment="center"
					gap={1}
				>
					<HeaderLogoButton
						link={Constants.paths.energyPage ?? '/'}
						image={{
							src: rootPageData.logo ?? '',
							alt: 'minside logo',
						}}
					/>
				</ContentGrid>
				<ContentGrid
					className={`${classPrefix}__center`}
					tagType={'nav'}
					direction="row"
					alignment="center"
					gap={1}
				>
					{_renderHeaderNavigation}
				</ContentGrid>
				<ContentGrid
					className={`${classPrefix}__right`}
					direction="row"
					alignment="center"
					tagType="nav"
					gap={3}
				>
					<AsyncRedirectButton
						text={goToMobileText}
						icon={IconType.SmartPhone}
						addition={'Redirect/mobile'}
					/>
					<LogoutButton
						theme={activeTheme}
						brand={activeBrand}
						text={getText('logoutText', rootPageData)}
						icon={IconType.LogoutIcon}
					/>
				</ContentGrid>
			</ContentGrid>
		);
	};

	const _renderMobile = () => {
		if (translation) {
			return (
				<StyleGrid
					className={`${classPrefix}__small-device`}
					direction="row"
					alignment="top-right"
					boxSizing="border-box"
				>
					<ActionButton
						brand={activeBrand}
						template={MS_ButtonTemplate(activeTheme, 'menu-icon')}
						customization={{
							icon: {
								width: 1.5,
								height: 1.5,
							},
						}}
						action={{
							text: getText('menu', rootPageData),
							icon: IconType.MenuBurger,
							onClick: () => {
								setToggleMobileMenu(true);
							},
						}}
					/>
					{toggleMobileMenu && (
						<MobileSelectMenu
							theme={activeTheme}
							brand={activeBrand}
							items={_getMobileNav()}
							onClose={() => {
								setToggleMobileMenu(false);
							}}
						/>
					)}
				</StyleGrid>
			);
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderHeader = useMemo(() => {
		return (
			<>
				{desktopView && _renderDesktop()}
				{!desktopView && _renderMobile()}
			</>
		);
	}, [
		desktopView,
		activeBrand,
		activeTheme,
		installation,
		rootPageData,
		activeNavigationElementId,
		toggleMobileMenu,
	]);

	// ************************************
	// Render
	// ************************************

	return _renderHeader;
};
