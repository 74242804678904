import { ContentLoader } from '@episerver/content-delivery';
import { Constants } from '../../../data';
import _ from 'lodash';
import {
	EpiPageType,
	IEpiPage,
	IEpiserverPlank,
	IFeatureStateRule,
	IHomePage,
	IIconItem,
	IServiceEpiPage,
	ITranslationItem,
} from '../../../models';
import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { isWhiteListed, logger } from '../HelperService';

// ************************************
// Base
// ************************************

export const getInitiatedContentLoader = (token: string): ContentLoader => {
	let { url, version, brand } = Constants.api;
	let baseUrl = `${url}/${version}/${brand}/`;

	return new ContentLoader({
		apiUrl: baseUrl,
		selectAllProperties: true,
		expandAllProperties: true,
		getHeaders: () =>
			Promise.resolve({
				'Accept-Language': 'no',
				Accept: 'application-json',
				'Content-Type': 'application-json',
				Authorization: `Bearer ${token}`,
			}),
	});
};

// ************************************
// Functionality
// ************************************

export const fetchEpiContent = async (l: ContentLoader, id: string): Promise<any | null> => {
	let data = await l
		.getContent(id)
		.then((content: any) => {
			let parsedData: any = dataParser(content);
			return parsedData;
		})
		.catch((error: any) => {
			logger(`Content not loaded: ${error.errorMessage} ID: ${id}`, 'error');
			return null;
		});

	return data;
};

export const fetchEpiChildren = async (l: ContentLoader, id: string = Constants.episerver.pageId ?? '') => {
	let data = await l
		.getChildren(id)
		.then((content: any) => {
			if (content?.length > 0) {
				return content;
			} else {
				return null;
			}
		})
		.catch((error: any) => {
			logger(`Content not loaded: ${error.errorMessage} ID: ${Constants.episerver.pageId}`, 'error');
			return null;
		});

	return data;
};

const dataParser = (epiResp: any) => {
	return epiResp;
};

export const simplifyEpiBlockCollection = (blocks: any[]) => {
	return blocks.map((block: any) => {
		return block.contentLink.expanded;
	});
};

export const findEpiBlockType = (contentType: string[], typeToUse: string) => {
	if (contentType && contentType.length > 0) {
		return _.find(contentType, (type: string) => {
			return type === typeToUse;
		});
	}
	return undefined;
};

export const findEpiPageId = (type: EpiPageType, epiMap: any) => {
	return epiMap[type].id;
};

export const getText = (key: string, translation: any, textsKey: string = 'translationItems') => {
	if (textsKey) {
		let foundList: ITranslationItem[] = translation[textsKey];

		if (foundList?.length > 0) {
			return hasText(key, foundList) ?? `MISSING: ${key}`;
		} else {
			return `MISSING: list or items ${textsKey}`;
		}
	} else {
		return 'MISSING: Text array reference';
	}
};

export interface IGetTextConfiguration {
	key: string;
	translations: any;
	textsKey?: string;
	includeMissing?: boolean;
}

export const getTextV2 = (config: IGetTextConfiguration) => {
	const { key, translations, textsKey = 'translationItems', includeMissing = true } = config;

	let text: string | undefined;

	if (textsKey && key) {
		let foundList: ITranslationItem[] = translations[textsKey];

		if (foundList && foundList.length > 0) {
			text = hasText(key, foundList);
		}

		if (!text && includeMissing) {
			text = `MISSING: ${key}`;
			logger(`${config.key} not found in getPlankText`, 'warn');
		}
	} else {
		return includeMissing ? 'MISSING: Text array reference' : undefined;
	}

	return includeMissing || text ? text : undefined;
};

export const hasText = (key: string, foundList: ITranslationItem[]) => {
	return (
		_.find(foundList, (translationItem: ITranslationItem) => {
			return translationItem.key === key;
		})?.value ?? undefined
	);
};

export interface IGetEpiPlankValues {
	key?: string;
	plank: IEpiserverPlank | any;
	includeMissing?: boolean;
	textsKey?: string;
}

// Get text from episerver, and place it in plank
export const getPlankText = (config: IGetEpiPlankValues) => {
	const { key, plank, includeMissing = true, textsKey = 'plankProperties' } = config;
	let text: string | undefined;

	if (textsKey && key) {
		let foundList: ITranslationItem[] = plank[textsKey];

		if (foundList.length > 0) {
			text = hasText(key, foundList);
		}

		if (!text && includeMissing) {
			text = `MISSING: ${key}`;
			logger(`${config.key} not found in getPlankText`, 'warn');
		}
	} else {
		return includeMissing ? 'MISSING: Text array reference' : undefined;
	}

	return includeMissing || text ? text : undefined;
};

// Get icon from episerver, and place it in plank
export const getPlankIcon = (config: IGetEpiPlankValues) => {
	const { key, plank, includeMissing = true } = config;
	let icon: IconType | undefined;

	if (plank.iconProperties && plank.iconProperties.length > 0) {
		plank.iconProperties.forEach((iconItem: IIconItem) => {
			if (iconItem.key === key) {
				if (Object.values(IconType).includes(iconItem.value)) {
					icon = IconType[iconItem.value];
				}
			}
		});
	}

	if (includeMissing && !icon) {
		icon = IconType.ErrorTriangle;
		logger(`${config.key} not found in getPlankIcon`, 'warn');
	}

	return includeMissing ? icon : undefined;
};

export const getEpiServicePage = (parent: IEpiPage, serviceId: string) => {
	let foundPage: any = {};

	if (parent?.children?.length > 0) {
		foundPage = _.find(parent.children, (child: IServiceEpiPage) => {
			if (child.data.servicePageId === serviceId) {
				return child.data;
			}
		});

		if (!foundPage) {
			logger(`Found no service page of ID: ${serviceId}`, 'warn');
		}
	} else {
		logger(`Found no children in epi service page parent: ${parent.type}`, 'warn');
	}

	return foundPage.data;
};

export const AllowFeature = (key: string, rootPage: IHomePage, user: any, customerServiceFeature: boolean) => {
	let allow: boolean = false;

	if (rootPage.featureStateRules && rootPage.featureStateRules.length > 0) {
		rootPage.featureStateRules.forEach((featureStateRule: IFeatureStateRule) => {
			if (key === featureStateRule.key && featureStateRule.enabled) {
				if (featureStateRule.customerServiceOnly && customerServiceFeature) {
					allow = true;
				} else if (featureStateRule.whitelistOnly && isWhiteListed(user)) {
					allow = true;
				} else if (!featureStateRule.customerServiceOnly) {
					allow = true;
				} else {
					allow = false;
				}
			}
		});
	}

	return allow;
};
