import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { Constants } from '../../../data';
import { AllowFeature, IsActiveCustomer, createString, getText, getParsedAddonStates } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { IAddonData, IServiceStatus } from '../../../models';
import { IStatePlank } from '../../../components';

export const ServicesPlank = (props: IHomePageData): IStatePlank | undefined => {
	const { epiChildren, translations, user, services } = props;
	const { installation } = user;
	const { customerServiceFeature } = services;

	if (
		AllowFeature(Constants.features.services, translations, services.user, customerServiceFeature) &&
		IsActiveCustomer(installation)
	) {
		return {
			promise: fetchServicesPlank(props, epiChildren),
		};
	}
};

const _getActiveServiceAmountText = (amount: number, translation: any) => {
	let activeText: string = '';

	if (amount === 0) {
		activeText = getText('plankServicesValueNone', translation);
	} else if (amount === 1) {
		activeText = createString(getText('plankServicesValue', translation), {
			amount: amount,
		});
	} else {
		activeText = createString(getText('plankServicesValuePlural', translation), {
			amount: amount,
		});
	}

	return activeText;
};

const fetchServicesPlank = async (props: IHomePageData, epiChildren: any) => {
	const { activeTheme, activeBrand, translations, services, user } = props;
	const { installation, userData } = user;
	const { GET } = services;

	let addonCount: number = 0;
	if (epiChildren) {
		let addonStates = await getParsedAddonStates(epiChildren, GET);

		if (addonStates) {
			addonStates.forEach((addon: IAddonData) => {
				if (installation) {
					let status: IServiceStatus | undefined = addon.state[installation?.meterId] as IServiceStatus;

					if (
						// Add the service to the addon count if its active or if we are customerService and its a customerServiceFeature and its not a whitelist feature
						status &&
						status.state === 'ACTIVE' &&
						!addon.page.data.whitelistFeature &&
						!addon.page.data.customerServiceFeature
					)
						addonCount++;
				}
			});
		}
	}

	// Add 1 to addons if Predictable Payment (aka. Steddi) is active on any account
	if (userData?.accounts.find((x) => x.steddiInfo)) {
		addonCount++;
	}

	return _getTextPlankPrefabTemplate({
		theme: activeTheme,
		brand: activeBrand,
		title: getText('plankServicesTitle', translations),
		description: getText('plankServicesDesc', translations),
		rightTitle: _getActiveServiceAmountText(addonCount, translations),
		link: Constants.paths.servicesPage,
		iconRight: IconType.ChevronRight,
	});
};
