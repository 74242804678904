import { handleAsyncData } from '../BaseService';
import { CallState } from '../../models';
import { logger } from './HelperService';
import { getRequest } from '../../contexts';

// ************************************
// MOVING
// ************************************

export const postMoveOutRequest = async (token: string, data: any) => {
	try {
		return await handleAsyncData({
			token,
			additions: 'MoveOut',
			method: 'POST',
			body: JSON.stringify(data),
		});
	} catch (e) {
		logger(`postMoveOutRequest failed: ${e}`, 'error');
		return {
			data: null,
			callState: 'error' as CallState,
		};
	}
};

// ************************************
// Async - GETTERS
// ************************************

export const fetchCustomerSummary = async (GET: getRequest) => {
	let resp = await GET('Customers/summary');
	return resp?.data;
};
