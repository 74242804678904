import React from 'react';
import App from './App';
import TagManager from 'react-gtm-module';
import WebFont from 'webfontloader';
import AuthenticationGuard from './authentication/AuthenticationGuard';
import { AuthProvider } from 'react-oidc-context';
import { authConfig } from './authentication/authConfig';
import { createRoot } from 'react-dom/client';
import './styling/styling.scss';

//Remove logged in status if we receive a login parameter
if (new URL(window.location.href).searchParams.get('li')) {
	Object.keys(window.localStorage)
		.filter((k) => k.startsWith('oidc.user:'))
		.forEach((k) => window.localStorage.removeItem(k));
}

WebFont.load({
	google: {
		families: ['Roboto:300,400,500,700'],
	},
});

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_ANALYTICS_GTM_FKAS ?? '',
};
TagManager.initialize(tagManagerArgs);

const domNode = document.getElementById('root');

if (domNode) {
	const root = createRoot(domNode);

	root.render(
		<React.StrictMode>
			<AuthProvider
				{...authConfig}
				automaticSilentRenew={false}
			>
				<AuthenticationGuard>
					<App />
				</AuthenticationGuard>
			</AuthProvider>
		</React.StrictMode>
	);
}
