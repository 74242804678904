import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { IStatePlank } from '../../../components';
import { Constants } from '../../../data';
import { ICustomerAccountInformation } from '../../../models';
import { AllowFeature, createString, getText } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { PrimaryPlankTemplate } from '../../../Prefabs/Templates';

export const SteddiPlank = (props: IHomePageData): IStatePlank | undefined => {
	const { user, translations, services } = props;
	const { userData } = user;
	const { customerServiceFeature } = services;

	if (AllowFeature(Constants.features.steddi, translations, services.user, customerServiceFeature)) {
		if (userData?.accounts && userData.accounts.length > 0) {
			let showSteddi: boolean = false;

			userData.accounts.forEach((acc: ICustomerAccountInformation) => {
				showSteddi = acc.steddiInfo ? true : false;
			});

			if (showSteddi) {
				return {
					promise: fetchPredictablePaymentPlank(props),
				};
			}
		}
	}
};

const fetchPredictablePaymentPlank = async (props: IHomePageData) => {
	const { activeTheme, translations, user } = props;
	const { userData } = user;

	let title = '';
	let description = '';
	let text: string = '';

	if (userData?.accounts && userData?.accounts.length > 1) {
		title = getText('plankPredictablePaymentTitleMultiple', translations);
		description = createString(getText('plankPredictablePaymentDescMultiple', translations), {
			amount: userData.accounts.length,
		});
		text = getText('plankPredictablePaymentValueMultiple', translations);
	} else {
		if (userData?.accounts[0].steddiInfo) {
			title = getText('plankPredictablePaymentTitle', translations);
			description = createString(getText('plankPredictablePaymentDesc', translations), {
				amount: userData.accounts[0].steddiInfo.monthlyAmount,
			});
			text = createString(getText('plankPredictablePaymentValue', translations), {
				amount: userData.accounts[0].steddiInfo.balance,
			});
		}
	}

	return _getTextPlankPrefabTemplate({
		theme: activeTheme,
		brand: 'brand-steddi',
		title: title,
		description: description,
		rightTitle: text,
		template: PrimaryPlankTemplate(activeTheme, 'single'),
		link: Constants.paths.predictablePayment,
		iconRight: IconType.ChevronRight,
		iconLeft: IconType.Steddi,
	});
};
