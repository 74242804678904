import { Accordion, BrandColors, IconType } from '@fjordkraft/fjordkraft.component.library';
import React, { useState, useEffect } from 'react';
import { PlankWall } from '../../blocks';
import { Constants } from '../../data';
import { IAccountOption, ICustomerAccountInformation } from '../../models';
import { Commode, getPlankPrefab, ITextPlankPrefab, TypedPlank } from '../../Prefabs';
import { getText } from '../../services';
import { useApplicationContext, useApplicationCoreDataContext } from '../../contexts';

export interface IFixedInvoiceDueDateSelector {
	accountInformation: {
		infos: ICustomerAccountInformation[];
		options: IAccountOption[];
	};
}

export const FixedInvoiceDueDateSelector = (props: IFixedInvoiceDueDateSelector) => {
	// ************************************
	// Properties
	// ************************************

	const { accountInformation } = props;
	const { activeTheme, activeBrand } = useApplicationContext();
	const { translation } = useApplicationCoreDataContext();
	const classPrefix = 'ms-fixed-invoice-due-date-selector';
	const dropdownId = `${classPrefix}__select-dropdown`;

	// ************************************
	// Lifecycle
	// ************************************

	const [content, setContent] = useState<TypedPlank[]>();

	useEffect(() => {
		_setInvoiceAccordionOptions();
	}, []);

	// ************************************
	// Handling (Async)
	// ************************************

	const _setInvoiceAccordionOptions = () => {
		let newContent: any[] = [];

		if (accountInformation?.infos && accountInformation?.options) {
			let fixedDueDateContentState = _getAccordionContent(accountInformation.infos, accountInformation.options);
			newContent = fixedDueDateContentState.content;
			setContent(newContent);
		}
	};

	// ************************************
	// Helpers
	// ************************************

	const _getAccordionItem = (content: TypedPlank[]) => {
		return Commode({
			theme: 'Light',
			brand: 'brand-fjordkraft',
			title: getText('plankFixedDueDateTitle', translation),
			description: getText('plankFixedDueDateDescSelect', translation),
			icon: IconType.Calendar,
			content,
		});
	};

	interface IAccordionPlankOption {
		info: ICustomerAccountInformation[];
		accounts: IAccountOption[];
		index: number;
		action: string;
		state: {
			subbed: boolean;
			allowed: boolean;
		};
	}

	const _getAccordionContent = (accountInfos: ICustomerAccountInformation[], accounts: IAccountOption[]) => {
		let state = { subbed: false, allowed: false };
		let content: TypedPlank[] = [];

		accountInfos.forEach((info: ICustomerAccountInformation, index: number) => {
			if (info) {
				state = {
					subbed: !!info.fixedDueDate,
					allowed: !info.steddiInfo,
				};

				let action: string = state.subbed ? '1' : '0';
				let option = _getAccordionPlankOption({
					info: accountInfos,
					accounts,
					index,
					state,
					action,
				});

				// Pr. summer 2023 we are only showing the option for fixed due date if the userData is already subbed
				if (state.subbed && state.allowed && option) {
					content.push(option);
				}
			}
		});

		return {
			content,
			allowed: state.allowed,
		};
	};

	const _getAccordionPlankOption = (config: IAccordionPlankOption) => {
		const { info, accounts, index, state, action } = config;

		let title = '';

		if (info.length > 1) {
			title = accounts[index].title;
		} else {
			title = state.subbed
				? getText('plankFixedDueDateTitleUnSub', translation)
				: getText('plankFixedDueDateTitle', translation);
		}

		return {
			type: 'Text',
			data: {
				left: {
					title,
					description: state.subbed
						? getText('plankFixedDueDateDescUnSub', translation)
						: getText('plankFixedDueDateDescSub', translation),
					icon: info.length > 1 ? undefined : IconType.Calendar,
					customization: {
						icon: {
							color: BrandColors['primary-shade-light-2'],
						},
					},
				},
				right: {
					description: state.subbed
						? getText('plankFixedDueDateValueUnSub', translation)
						: getText('plankFixedDueDateValueSub', translation),
					customization: {
						description: {
							color: state.subbed ? BrandColors['status-shade-light-1'] : undefined,
							weight: 500,
						},
					},
					icon: IconType.ChevronRight,
				},
				action: {
					link: `${Constants.paths.fixedDueDate}/${action}/${accounts[index].value}`,
				},
			} as ITextPlankPrefab,
		} as TypedPlank;
	};

	// ************************************
	// Render
	// ************************************

	return (
		<>
			{content && content.length > 1 && (
				<Accordion
					id={dropdownId}
					theme={activeTheme}
					brand={activeBrand}
					elements={[_getAccordionItem(content)]}
				/>
			)}
			{content && content.length === 1 && (
				<PlankWall
					brand={activeBrand}
					theme={activeTheme}
					planks={[getPlankPrefab(content[0].type, content[0].data)]}
				/>
			)}
		</>
	);
};
