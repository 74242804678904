import React, { useEffect, useState } from 'react';
import { IAction, StyleGrid } from '@fjordkraft/fjordkraft.component.library';
import { IBlock, IEpiAction, ITranslationItem } from '../../models';
import { CommercialBanner } from '../../components';
import classNames from 'classnames';
import { useApplicationContext } from '../../contexts';
import { getText } from '../../services';
import './CommercialBlock.scss';

export interface EpiCommercial {
	action?: IEpiAction;
	active: boolean;
	blockId: string;
	commercialId?: string;
	image?: string;
	modelType: string;
	priority: number;
	translationItems: ITranslationItem[];
}

export interface ICommercialBlock extends IBlock {
	pageId: string;
	commercials: EpiCommercial[];
}

export const CommercialBlock = (props: ICommercialBlock) => {
	// ************************************
	// Properties
	// ************************************

	const { id, pageId, className, theme, brand, commercials } = props;
	const classPrefix = 'commercial-block';
	const { desktopView } = useApplicationContext();
	const storageId = `commercials-${pageId}`;

	// ************************************
	// Lifecycle
	// ************************************

	const [showCommercials, setShowCommercials] = useState<boolean>(true);

	useEffect(() => {
		if (localStorage.getItem(storageId)) {
			setShowCommercials(localStorage.getItem(storageId) !== 'true');
		}
	}, []);

	// ************************************
	// Priority handling
	// ************************************

	const _fetchPrioritizedItem = (): EpiCommercial | undefined => {
		const activeCommercials = commercials.filter((commercial) => commercial.active);
		const totalPriorityWeight = activeCommercials.reduce((sum, item) => sum + item.priority, 0);

		const mappedCommercials = [];
		let priority = 0;

		for (const commercial of activeCommercials) {
			priority += commercial.priority;
			mappedCommercials.push({
				commercial: commercial,
				priority: priority,
			});
		}

		// Using crypto because SonarQube hates Math.random():
		// 1. Generate random byte,
		// 2. Normalize value from 0 - MAX_INTEGER to 0 - 1,
		// 3. Multiply with total weight.
		const array = new Uint32Array(1);
		crypto.getRandomValues(array);
		const randomPriority = (array[0] / (0xffffffff + 1)) * totalPriorityWeight;

		return mappedCommercials.find((commercial) => randomPriority <= commercial.priority)?.commercial;
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderLikelyCommercial = () => {
		let commercial = _fetchPrioritizedItem();

		if (commercial) {
			return (
				<CommercialBanner
					action={
						commercial.action
							? ({
									text: commercial.action.text,
									link: commercial.action.url,
							  } as IAction)
							: undefined
					}
					priority={commercial.priority}
					title={getText('title', commercial)}
					description={getText('description', commercial)}
					extra={{
						title: getText('extraTitle', commercial),
						description: getText('extraDescription', commercial),
					}}
					image={
						commercial.image
							? {
									src: commercial.image,
									alt: 'Commercial',
							  }
							: undefined
					}
					brand={brand}
					theme={theme}
					desktopView={desktopView}
					close={() => {
						setShowCommercials(false);
						localStorage.setItem(storageId, 'true');
					}}
				/>
			);
		}
	};

	// ************************************
	// Render
	// ************************************

	return (
		<>
			{showCommercials && (
				<StyleGrid
					id={id}
					className={classNames(classPrefix, {
						[`${className}`]: className,
					})}
					brand={brand}
					direction="column"
					alignment="top-left"
				>
					{_renderLikelyCommercial()}
				</StyleGrid>
			)}
		</>
	);
};
