import { CallState } from '@fjordkraft/fjordkraft.component.library';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	CustomerInfoEditorModal,
	DigitVerificationModal,
	ICustomerEditorInformation,
	IDigitVerificationModal,
} from '../../../modals';
import {
	useApplicationAccountSelectContext,
	useApplicationOverlayWrapperContext,
	useApplicationCoreDataContext,
	useApplicationDefaultContext,
} from '../../../contexts';
import { capitalizeFirstLetter, getText } from '../../../services';
import { Constants } from '../../../data';
import { ApplicationUserEditWrapperContext } from '../../../contexts/variations/ApplicationUserEditWrapper';
import { GetToastPrefab, ToastPrefabsType } from '../../../Prefabs';
import { IWebToast } from '../../../blocks';

export interface ICustomerDataToEdit {
	fields: ICustomerEditorInformation;
	topText: string;
	bottomText: string;
	title: string;
}

export const ApplicationUserEditWrapper = (props: any) => {
	// ************************************
	// Properties
	// ************************************

	const { defaultProps } = useApplicationDefaultContext();
	const { updateCustomerData } = useApplicationCoreDataContext();
	const { setTempToasts } = useApplicationOverlayWrapperContext();
	const { activeAccount } = useApplicationAccountSelectContext();
	const { pathname } = useLocation();

	// ************************************
	// Lifecycle
	// ************************************

	const [customerDataToEdit, setCustomerDataToEdit] = useState<ICustomerDataToEdit | undefined>();
	const [digitPopupData, setDigitPopupData] = useState<IDigitVerificationModal>();
	const [changeState, setChangeState] = useState<CallState>('idle');

	// ************************************
	// userData Info Handling
	// ************************************

	const onSubmitFormChange = (customerInformation: ICustomerEditorInformation) => {
		if (customerInformation) {
			setChangeState('pending');

			let myPagePath = `${Constants.paths.userPage}${Constants.paths.invoiceSettings}`;
			let invoicePagePath = `${Constants.paths.invoicePage}${Constants.paths.invoiceSettings}`;

			if (pathname === (myPagePath || invoicePagePath)) {
				if (customerInformation.email && customerInformation.email.length > 0) {
					_updateInvoiceEmail(customerInformation.email);
				} else if (
					customerInformation.address &&
					customerInformation.postalCode &&
					customerInformation.postalLocation
				) {
					_updateInvoiceAgreementAddress(
						customerInformation.address,
						customerInformation.postalCode,
						customerInformation.postalLocation
					);
				}
			} else {
				if (customerInformation.email && customerInformation.email.length > 0) {
					_updateEmail(customerInformation.email);
				} else if (customerInformation.phoneNumber && customerInformation.phoneNumber.length > 0) {
					_updatePhone(customerInformation.phoneNumber);
				}
			}
		}
	};

	// ************************************
	// Field Handlers
	// ************************************

	const _updateEmail = async (newValue: string) => {
		const { PUT } = defaultProps.services;

		let resp = await PUT('Customers/email/update', newValue);
		setChangeState('idle');
		await updateCustomerData(true);
		_handleToasts(resp.callState, 'Email');
		setCustomerDataToEdit(undefined);
	};

	const _updateInvoiceEmail = async (newValue: string) => {
		const { POST } = defaultProps.services;

		let resp = await POST(`Account/${_getAccountId()}/updateCustomerData`, {
			email: newValue,
		});

		setChangeState('idle');
		await updateCustomerData(true);
		_handleToasts(resp.callState, 'InvoiceEmail');
	};

	const _updateInvoiceAgreementAddress = async (address: string, postalCode: string, postalLocation: string) => {
		const { POST } = defaultProps.services;

		let addressInfo: string[] = address.split(
			new RegExp('(?<street>\\D+)?\\s*?(?<house>\\d+)?\\s*?(?<letter>\\D+)?')
		);

		let resp = await POST(`Account/${_getAccountId()}/updateCustomerData`, {
			streetName: addressInfo[1],
			streetNumber: addressInfo[2],
			streetletter: addressInfo[3],
			zip: postalCode,
			city: postalLocation,
		});

		setChangeState('idle');
		await updateCustomerData(true);
		_handleToasts(resp.callState, 'InvoiceAddress');
	};

	const _updatePhone = async (newValue: string) => {
		const { translations, services } = defaultProps;
		const { PUT } = services;

		await PUT('Customers/initiateChangePhoneNumber', newValue);

		setDigitPopupData({
			translation: translations,
			verified: async (callState: CallState) => {
				await updateCustomerData(true);
				setChangeState('idle');
				_handleToasts(callState, 'Phone');
				setDigitPopupData(undefined);
			},
			onClose: () => {
				setDigitPopupData(undefined);
			},
			changedValue: newValue,
		});

		setCustomerDataToEdit(undefined);
	};

	const _handleToasts = (callState: CallState, type: string) => {
		const { translations, desktopView } = defaultProps;

		setTempToasts([
			GetToastPrefab(ToastPrefabsType.CallStateToast, {
				text: getText(`update${type}${capitalizeFirstLetter(callState)}`, translations),
				status: callState,
				desktopView,
			}) as IWebToast,
		]);
		setCustomerDataToEdit(undefined);
	};

	// ************************************
	// Helpers
	// ************************************

	const _getAccountId = () => {
		const { userData } = defaultProps.user;

		if (activeAccount?.accountId) {
			return activeAccount?.accountId;
		} else if (userData?.accounts[0].accountId) {
			return userData?.accounts[0].accountId;
		} else {
			return '';
		}
	};

	const _contextData = useMemo(() => {
		return {
			customerDataToEdit,
			onSubmitFormChange,
			setCustomerDataToEdit,
		};
	}, [customerDataToEdit]);

	// ************************************
	// Render
	// ************************************

	return (
		<>
			{customerDataToEdit && (
				<CustomerInfoEditorModal
					theme={defaultProps.activeTheme}
					brand={defaultProps.activeBrand}
					title={customerDataToEdit.title}
					topText={customerDataToEdit.topText}
					bottomText={customerDataToEdit.bottomText}
					fields={customerDataToEdit.fields}
					callState={changeState}
					onClose={() => {
						setChangeState('idle');
						setCustomerDataToEdit(undefined);
					}}
					onSubmitFormChange={(fields: ICustomerEditorInformation) => {
						onSubmitFormChange(fields);
					}}
				/>
			)}
			{digitPopupData && defaultProps.translations && <DigitVerificationModal {...digitPopupData} />}
			<ApplicationUserEditWrapperContext.Provider value={_contextData}>
				{props.children}
			</ApplicationUserEditWrapperContext.Provider>
		</>
	);
};
