import { createString } from './../../../services/collection/HelperService';
import { IAction, IMasterPlank, IconType } from '@fjordkraft/fjordkraft.component.library';
import { ITextPlankPrefab, getPlankPrefab } from '../../../Prefabs';
import { Constants } from '../../../data';
import { format } from 'date-fns';
import {
	IAddonData,
	ICustomer,
	ICustomerAccountInformation,
	ICustomerInstallation,
	IDefaultProps,
	IServiceEpiPage,
	IServicePage,
	IServiceStatus,
} from '../../../models';
import { getServiceStatusColor, getText, isWhiteListed } from '../../../services';
import { IArticleItem } from '../../../components';
import { IPlankHouse } from '../../../blocks';

// ************************************
// Public
// ************************************

export interface IServiceOverviewPageData extends IDefaultProps {
	addonStates?: IAddonData[];
}

export const getPageContent = async (config: IServiceOverviewPageData) => {
	const { translations, user } = config;
	const { installation } = user;

	if (translations && installation) {
		let availablePages = await getAvailableServicePages(config);
		let selection: any = _parseServiceData(availablePages, translations);

		return {
			...config,
			sub: {
				title: getText('pageTitle', translations),
				back: {
					text: installation.address.streetAddress,
					link: Constants.paths.energyPage,
				},
			},
			house: _getHouse(availablePages, selection),
			availableServices: {
				title: getText('servicesTitle', translations),
				items: selection.articles,
			},
		};
	}
};

const _getHouse = (services: IServicePage[], selection: any) => {
	let house: IPlankHouse | undefined;

	if (services?.length > 0 && selection.activeProducts?.length > 0) {
		house = {
			plankWalls: [
				{
					planks: selection.activeProducts,
				},
			],
		};
	}

	return house;
};

export const getAvailableServicePages = async (config: IServiceOverviewPageData) => {
	const { epiChildren, user, addonStates, services } = config;
	const { userData, installation } = user;
	const { customerServiceFeature } = services;

	let availablePages: IServicePage[] = [];

	if (installation && epiChildren && userData && addonStates) {
		addonStates.forEach((data: IAddonData) => {
			let status: IServiceStatus | undefined = data.state[installation.meterId] as IServiceStatus;

			if (data.page.data.servicePageId === 'forutsigbar') {
				status = _handleSteddiStatus(data.page, installation, userData);
			}

			if (status) {
				data.page.data.serviceStatus = {
					fromDate: status.fromDate,
					orderId: status.orderId,
					state: status.state,
					meterId: installation.meterId,
					productDefinitionId: data.page.data.productDefinitionId,
				} as IServiceStatus;

				if (_shouldServiceBeAvailable(data.page.data, services.user, customerServiceFeature, status)) {
					availablePages.push(data.page.data);
				}
			}
		});
	}

	return availablePages;
};

// ************************************
// Private
// ************************************

const _parseServiceData = (services: IServicePage[], translation: any) => {
	let articles: IArticleItem[] = [];
	let activeProducts: IMasterPlank[] = [];

	services.forEach((servicePage: IServicePage) => {
		switch (servicePage.serviceStatus?.state) {
			case 'ACTIVE':
			case 'ACTIVATING':
			case 'ORDER_IN_PROGRESS':
			case 'ACTIVE_FUTURE':
			case 'ORDER_WAITING_FOR_CUSTOMER':
			case 'ORDER_FAILED':
			case 'TERMINATING':
				activeProducts.push(_getServiceStatusPlank(servicePage, translation));
				break;
			case 'TERMINATED':
			case 'ORDER_CANCELLED_BY_CUSTOMER':
			case 'INACTIVE':
				articles.push({
					label: servicePage?.pitch?.label ?? '',
					title: servicePage?.pitch?.title ?? '',
					description: servicePage.pitch.description ?? '',
					additions: servicePage.pitch.additions,
					action: {
						text: servicePage?.pitch?.action?.text ?? undefined,
						link: `${servicePage.servicePageId}`,
					} as IAction,
					image: {
						src: servicePage?.pitch?.image,
						alt: `${servicePage.servicePageType} bilde`,
					},
				} as IArticleItem);
				break;
		}
	});

	return { articles, activeProducts };
};

const _getServiceStatusPlank = (service: IServicePage, translation: any) => {
	return getPlankPrefab('Text', {
		left: {
			title: getText('pageTitle', service),
			description: getText('activeServicePlankDesc', service),
		},
		right: {
			description: _getPlankStatusValues(service.serviceStatus, translation),
			icon: IconType.ChevronRight,
			customization: {
				description: {
					color: getServiceStatusColor(service.serviceStatus?.state),
					weight: 600,
				},
			},
		},
		action: {
			link: `${service.servicePageId}`,
		} as IAction,
	} as ITextPlankPrefab);
};

const _handleSteddiStatus = (
	servicePage: IServiceEpiPage,
	installation: ICustomerInstallation,
	userData?: ICustomer
) => {
	if (userData?.accounts && installation) {
		let hasSteddi: boolean =
			userData.accounts.find((account: ICustomerAccountInformation) => {
				return account.steddiInfo;
			}) !== undefined;

		return {
			fromDate: new Date().toDateString(),
			orderId: '',
			state: hasSteddi ? 'ACTIVE' : 'INACTIVE',
			productDefinitionId: servicePage.data.productDefinitionId,
			meterId: installation?.meterId ?? '',
		} as IServiceStatus;
	}
	return undefined;
};

const _shouldServiceBeAvailable = (
	page: IServicePage,
	user: any,
	loggedInAsCustomerService: boolean,
	status: IServiceStatus
) => {
	if (page.servicePageShow) {
		if (page.showForActiveCustomersOnly && !_hasServiceActivated(status)) {
			return false;
		} else {
			return _serviceFeatureCheck(page, user, loggedInAsCustomerService);
		}
	} else {
		return false;
	}
};

const _serviceFeatureCheck = (page: IServicePage, user: any, loggedInAsCustomerService: boolean) => {
	if (page.customerServiceFeature && loggedInAsCustomerService) {
		return true;
	} else if (page.whitelistFeature && isWhiteListed(user)) {
		return true;
	} else if (!page.customerServiceFeature) {
		return true;
	} else {
		return false;
	}
};

const _hasServiceActivated = (status: IServiceStatus) => {
	switch (status.state) {
		case 'ACTIVE':
		case 'ACTIVE_FUTURE':
		case 'ORDER_IN_PROGRESS':
		case 'ORDER_WAITING_FOR_CUSTOMER':
		case 'ACTIVATING':
		case 'TERMINATING':
			return true;
		default:
			return false;
	}
};

const _getPlankStatusValues = (status: IServiceStatus | undefined, translation: any): string | undefined => {
	switch (status?.state) {
		case 'ACTIVE':
			return getText('plankServiceActive', translation);
		case 'ACTIVE_FUTURE':
			if (status.fromDate)
				return createString(getText('plankServiceActiveFuture', translation), {
					date: format(new Date(status.fromDate), 'dd.MM.yyyy'),
				});
			break;
		case 'TERMINATED':
			return getText('plankServiceTerminated', translation);
		case 'ORDER_CANCELLED_BY_CUSTOMER':
			return getText('plankServiceCancelledByCustomer', translation);
		case 'ORDER_FAILED':
			return getText('plankServiceOrderFailed', translation);
		case 'ACTIVATING':
		case 'TERMINATING':
		case 'ORDER_IN_PROGRESS':
		case 'ORDER_WAITING_FOR_CUSTOMER':
			return getText('plankServiceOrderInProgress', translation);
	}
};
