import React, { useState } from 'react';
import {
	useApplicationAccountSelectContext,
	useApplicationCoreDataContext,
	useApplicationOverlayWrapperContext,
	useApplicationUserEditWrapperContext,
} from '../../../contexts';
import {
	BrandColors,
	CallState,
	IRadioPlankSelectForm,
	IconType,
	StyleGrid,
	Text,
	RadioPlankSelectForm,
	getCounterTheme,
} from '@fjordkraft/fjordkraft.component.library';
import { DynamicEpiContentBlock, IPlankHouse, IWebToast, PlankHouseBlock } from '../../../blocks';
import { ICustomer, InvoiceDeliveryMethodType } from '../../../models';
import { getDeliveryMethodOptions, getPageContent } from './InvoiceSettingsPageData';
import {
	GetToastPrefab,
	ToastPrefabsType,
	h4TextPrefab,
	paragraphTextPrefab,
	smallParagraphTextPrefab,
} from '../../../Prefabs';
import { IResponse, getText } from '../../../services';
import { TipCard } from '../../../components';
import { DirectDebitModal } from '../../../modals';
import { Constants } from '../../../data';
import { useLocation } from 'react-router-dom';
import { IDefaultViewProps, PageV2 } from '../../PageV2';
import './InvoiceSettingsPage.scss';

export type DirectDebitStatus = 'None' | 'Active' | 'Pending' | 'Rejected' | 'Cancelled';

export interface IInvoiceSettingsPage extends IDefaultViewProps {
	invoiceSettings: IPlankHouse;
}

export const InvoiceSettingsPage = () => {
	// ************************************
	// Properties
	// ************************************

	const classPrefix = 'invoice-settings-page';
	const { setTempToasts } = useApplicationOverlayWrapperContext();
	const { setCustomerDataToEdit } = useApplicationUserEditWrapperContext();
	const { setUseAccountSelectMenu, activeAccount, setIncludeAllOption } = useApplicationAccountSelectContext();
	const { updateCustomerData } = useApplicationCoreDataContext();
	const location = useLocation();

	// ************************************
	// Lifecycle
	// ************************************

	const [deliveryMethodCallState, setDeliveryMethodCallState] = useState<CallState>('idle');
	const [showDirectDebitPopup, setShowDirectDebitPopup] = useState<boolean>(false);

	// ************************************
	// Handlers
	// ************************************

	const _updateDeliveryMethod = async (
		accountNumber: string,
		method: InvoiceDeliveryMethodType,
		config: IInvoiceSettingsPage
	): Promise<IResponse> => {
		const { services } = config;
		const { PUT } = services;

		setDeliveryMethodCallState('pending');
		let resp: IResponse;

		try {
			resp = await PUT(`Account/${accountNumber}/deliverymethod/${method}`);

			if (resp.callState === 'success') {
				await updateCustomerData(true);
				setDeliveryMethodCallState('idle');
				_handleToasts(resp.callState, 'deliveryMethod', config);
			} else if (resp.callState === 'error' || resp.callState == 'aborted') {
				setDeliveryMethodCallState('idle');
				throw Error(`deliveryMethod: ${resp.callState}`);
			}
			return resp;
		} catch (e) {
			setDeliveryMethodCallState('idle');
			throw Error(`deliveryMethod: ${e}`);
		}
	};

	const _handleToasts = (callState: CallState, type: string, config: IInvoiceSettingsPage) => {
		const { translations } = config;

		if (callState === 'success') {
			setTempToasts([
				GetToastPrefab(ToastPrefabsType.StatusToast, {
					text: getText(`update${type}Success`, translations),
					status: 'positive',
				}) as IWebToast,
			]);
		} else if (callState === 'error') {
			setTempToasts([
				GetToastPrefab(ToastPrefabsType.StatusToast, {
					text: getText(`update${type}Failed`, translations),
					status: 'negative',
				}) as IWebToast,
			]);
		}
	};

	// ************************************
	// Helpers
	// ************************************

	const _hasSteddi = (userData: ICustomer) => {
		return userData?.accounts.find((e) => e.accountId === activeAccount?.accountId && e.steddiInfo);
	};

	const _hasAccounts = (userData: ICustomer) => {
		return userData.accounts.find((e) => e);
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderTipCard = (config: IInvoiceSettingsPage) => {
		const { translations } = config;

		return (
			<TipCard
				brand={'brand-steddi'}
				iconType={IconType.InfoBubble}
				iconColor={BrandColors['primary-shade-light-2']}
				title={getText('invoiceSteddiInfoBoxTitle', translations)}
				content={getText('invoiceSteddiInfoBoxContent', translations)}
			/>
		);
	};

	const _renderDeliverySelection = (config: IInvoiceSettingsPage) => {
		const { translations, activeBrand, activeTheme, user, relationship } = config;
		const { userData } = user;
		const { isGuest } = relationship;

		if (userData && activeAccount?.deliveryMethod && translations) {
			if (!_hasSteddi(userData)) {
				let formData = getDeliveryMethodOptions(translations, activeAccount, setCustomerDataToEdit);
				let selectForm: IRadioPlankSelectForm = {
					items: formData.options,
					initialActiveIndex: formData.selectedIndex,
					onChange: () => {},
					asyncOnChange: async (value: any) => {
						await _updateDeliveryMethod(activeAccount.accountId, value.id, config);
					},
				};

				return (
					<RadioPlankSelectForm
						{...selectForm}
						disabled={deliveryMethodCallState !== 'idle' || isGuest}
						brand={activeBrand}
						theme={activeTheme}
					/>
				);
			}
		} else if (!activeAccount?.deliveryMethod && translations) {
			return (
				<Text
					{...smallParagraphTextPrefab()}
					style="italic"
					brand={activeBrand}
					theme={getCounterTheme(activeTheme)}
				>
					{getText('deliveryMethodError', translations)}
				</Text>
			);
		}
	};

	// ************************************
	// Render
	// ************************************

	return PageV2({
		setup: {
			pageType: Constants.epiServerPageNames.invoiceSettings.type,
			usesSubPage: true,
		},
		dependencies: [
			{ activeAccount },
			{ setShowDirectDebitPopup },
			{ location },
			{ showDirectDebitPopup },
			{ deliveryMethodCallState },
		],
		handleData: getPageContent,
		startedRender: () => {
			setIncludeAllOption(false);
			setUseAccountSelectMenu(true);
		},
		render: (config: IInvoiceSettingsPage) => {
			const { translations, activeBrand, activeTheme, user, invoiceSettings } = config;
			const { userData } = user;

			return (
				<StyleGrid
					className={`${classPrefix}`}
					direction="column"
					alignment="top-center"
					gap={4}
				>
					{translations.topEditorialContent && (
						<DynamicEpiContentBlock
							theme={activeTheme}
							brand={activeBrand}
							epiItems={translations.topEditorialContent}
						/>
					)}
					<PlankHouseBlock
						className={`${classPrefix}__house`}
						brand={activeBrand}
						theme={activeTheme}
						{...invoiceSettings}
					/>
					<StyleGrid
						className={`${classPrefix}__delivery`}
						direction="column"
						alignment="top-left"
						gap={2}
						boxSizing="border-box"
					>
						<Text
							{...h4TextPrefab()}
							className={`${classPrefix}__delivery-type__title`}
							theme={getCounterTheme(activeTheme)}
							brand={activeBrand}
							weight={700}
						>
							{getText('deliveryTypeTitle', translations)}
						</Text>
						{!_hasAccounts(userData) && (
							<Text
								{...paragraphTextPrefab()}
								theme={getCounterTheme(activeTheme)}
								brand={activeBrand}
							>
								{getText('invoicesMissingText', translations)}
							</Text>
						)}
						{_hasSteddi(userData) && _renderTipCard(config)}
						{_renderDeliverySelection(config)}
					</StyleGrid>
					{showDirectDebitPopup && activeAccount && (
						<DirectDebitModal
							translations={translations}
							account={activeAccount}
							onClose={() => {
								setShowDirectDebitPopup(false);
							}}
						/>
					)}
				</StyleGrid>
			);
		},
	});
};
