import React from 'react';
import { BaseComponent, ContentGrid, getCounterTheme, IAction, IBaseComponent, IconType, IImage, Image, StyleGrid, Text } from "@fjordkraft/fjordkraft.component.library";
import classNames from "classnames";
import { ActionButton } from '../Buttons/ActionButton';
import { CommercialTemplate, h3TextPrefab, h4TextPrefab, MS_ButtonTemplate, paragraphTextPrefab } from '../../Prefabs';
import { MSRichText } from '../MSRichText/MSRichText';
import "./CommercialBanner.scss";

export interface ICommercialBanner extends IBaseComponent {
    action?: IAction;
    title?: string;
    description?: string;
    image?: IImage;
    priority: number;
    desktopView: boolean;
    extra?: {
        title: string;
        description: string;
    }
    close?: () => void;
}

export const CommercialBanner = (props: ICommercialBanner) => {

    // ************************************
    // Properties
    // ************************************

    const {
        id,
        brand,
        theme = 'Light',
        className,
        action,
        title,
        description,
        image,
        desktopView,
        extra,
        template = CommercialTemplate(theme),
        close
    } = props;
    const classPrefix = 'commercial-banner';

    // ************************************
    // Helpers
    // ************************************

    // ************************************
    // Render
    // ************************************

    return (
        <BaseComponent
            id={id}
            brand={brand}
            theme={theme}
            template={template}
            className={classNames(classPrefix, {
                [`${className}`]: className
            })}
        >
            <StyleGrid
                className={`${classPrefix}__top`}
                direction={desktopView ? 'row' : 'column'}
                alignment='top-left'
                gap={3}
                boxSizing={'border-box'}
                wrap={false}
            >
                {image &&
                    <Image
                        {...image}
                        className={`${classPrefix}__top__image`}
                        fit={'cover'}
                        scalingBy={'height'}
                    />
                }
                <ContentGrid
                    className={classNames(`${classPrefix}__top__header`, {
                        [`${classPrefix}__top__header--clamp`]: image
                    })}
                    direction='column'
                    alignment='top-left'
                    tagType={'section'}
                    boxSizing={'border-box'}
                >
                    {(title || close !== undefined) &&
                        <ContentGrid
                            className={`${classPrefix}__top__header__nav`}
                            direction='row'
                            alignment='top-left'
                            tagType={close !== undefined ? 'nav' : 'section'}
                            spaceBetween
                        >
                            <Text
                                {...h3TextPrefab()}
                                palette={template.palette}
                            >
                                {title}
                            </Text>
                            {close &&
                                <ActionButton
                                    action={{
                                        icon: IconType.Close,
                                        onClick: () => {
                                            close();
                                        }
                                    }}
                                    brand={brand}
                                    padding={'small'}
                                    iconPlacement='Right'
                                    template={MS_ButtonTemplate(theme, 'icon')}
                                />
                            }
                        </ContentGrid>
                    }
                    {description &&
                        <MSRichText
                            className={`${classPrefix}__top__desc`}
                            palette={template.palette}
                            theme={getCounterTheme(theme)}
                            brand={brand}
                            text={description}
                            alignment='lawful'
                        />
                    }
                </ContentGrid>
            </StyleGrid>
            <StyleGrid
                direction='row'
                alignment='top-left'
                className={`${classPrefix}__bottom`}
                boxSizing={'border-box'}
                spaceBetween
            >
                {extra &&
                    <ContentGrid
                        className={`${classPrefix}__bottom__extra`}
                        direction='column'
                        alignment='top-left'
                        tagType={'section'}
                        boxSizing={'border-box'}
                    >
                        <Text
                            {...h4TextPrefab()}
                            palette={template.palette}
                        >
                            {extra.title}
                        </Text>
                        <Text
                            {...paragraphTextPrefab()}
                            palette={template.palette}
                            faded
                        >
                            {extra.description}
                        </Text>
                    </ContentGrid>
                }
                {action &&
                    <ContentGrid
                        direction='column'
                        alignment='top-left'
                        tagType={'nav'}
                        className={`${classPrefix}__bottom__nav`}
                        boxSizing={'border-box'}
                    >
                        <ActionButton
                            action={action}
                            brand={brand}
                            padding={desktopView ? 'small' : 'medium'}
                            iconPlacement='Right'
                            template={MS_ButtonTemplate(theme, 'primary')}
                        />
                    </ContentGrid>
                }
            </StyleGrid>
        </BaseComponent>
    );
}