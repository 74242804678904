import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { ColumnChart, IChartTooltip, BrandColors, getColorCode } from '@fjordkraft/fjordkraft.component.library';
import { IChartCard, ChartCard } from '../ChartCard';
import { ILegend } from '../../../Legend/Legend';
import { useApplicationContext } from '../../../../contexts';

export interface IColumnChartCard extends IChartCard {
	series: any[];
	categories?: string[];
	chartTooltip?: IChartTooltip;
	legends?: ILegend[];
	stack?: boolean;
	max?: number;
	chartColors?: BrandColors[];
	forceNiceScale?: boolean;
	showEstimatedView?: boolean;
	onColumnClick?: (x: any, y: any, dataPoint?: any) => void;
}

export const DefaultColumnChartColors = [
	BrandColors['chart-shade-light-1'],
	BrandColors['chart-shade-light-2'],
	BrandColors['chart-shade-light-3'],
	BrandColors['chart-shade-dark-1'],
	BrandColors['chart-shade-dark-2'],
	BrandColors['chart-shade-dark-3'],
]

export const ColumnChartCard = (props: IColumnChartCard) => {
	// ************************************
	// Properties
	// ************************************

	const {
		id = 'd30cffbd-db42-4aa9-adf7-7c1d3a22a2ab',
		className,
		brand,
		theme,
		series,
		max,
		categories,
		chartTooltip,
		legends,
		stack = false,
		onColumnClick,
		csvData,
		chartColors = DefaultColumnChartColors,
		forceNiceScale,
		showEstimatedView = false
	} = props;
	const classPrefix = 'ms-column-chart-card';
	const { desktopView } = useApplicationContext();

	// ************************************
	// Lifecycle
	// ************************************

	const [clampValues, setClampValues] = useState<any>({ min: 0, max: 1 });

	useEffect(() => {
		findClampValues();
	}, [series, desktopView]);

	// ************************************
	// Helper Functionality
	// ************************************

	const findClampValues = () => {
		let min = 9999;
		let foundMax = max ?? 1;

		if (series.length > 0) {
			// 0: energySection / aktuell kostnad, 1: known / kost u/nettleie, 2: support / strømstøtte
			series[0].data.forEach((el: { x: string; y: number; type: string }, index: number) => {
				// iterates through a month-array, el is object, x day, y value for said day
				let sum = el.y;
				for (let col = 1; col < series.length; col++) {
					// loops through the other available arrays at the same index to sum together
					let dayValue = series[col].data[index].y;
					if (dayValue !== undefined) {
						sum += dayValue ?? 0;
					}
				}
				foundMax = Math.max(foundMax, sum);
				min = Math.min(min, sum);
			});
		}

		foundMax = Math.ceil(foundMax * 1.1);

		if (min < 0) {
			min = -Math.abs(min) * 1.1;
		} else {
			min = 0;
		}

		setClampValues({ min, max: foundMax });
	};

	const getDynamicBarWidth = () => {
		switch (series.length) {
			case 1:
				if (series[0].data.length > 12) {
					return '40%';
				} else {
					return '80%';
				}
			case 2:
				return '40%';
			case 3:
				if (series[0].data.length > 3) return '40%';
				else return '60%';
			case 4:
				return '70%';
			case 5:
				return '80%';
		}
	};

	const getDynamicBarHeight = () => {
		if (desktopView) {
			return '100%';
		} else {
			switch (series.length) {
				case 1:
					if (series[0].data.length > 12) {
						return '55%';
					} else {
						return '80%';
					}
				case 2:
					return '40%';
				case 3:
					return '60%';
				case 4:
					return '80%';
				case 5:
					return '100%';
			}
		}
	};

	const _getChartColors = () => {
		if (chartColors) {
			return chartColors.map((color: BrandColors) => {
				return getColorCode({
					color: color,
					type: 'rgb',
					element: document.getElementById(id),
				});
			});
		}

		return [
			getColorCode({
				color: BrandColors['chart-shade-light-1'],
				type: 'rgb',
				element: document.getElementById(id),
			}),
		];
	};

	// ************************************
	// Render
	// ************************************

	return (
		<ChartCard
			{...props}
			id={id}
			className={classnames(classPrefix, {
				[`${className}`]: className,
			})}
			legends={legends}
			csvData={csvData}
			showEstimatedView={showEstimatedView}
		>
			{series && series.length > 0 && clampValues && (
				<ColumnChart
					className={`${classPrefix}__chart`}
					series={series}
					element={document.getElementById('root')}
					type={'bar'}
					height={desktopView ? '300px' : '600px'}
					categories={categories}
					brand={brand}
					theme={theme ?? 'Light'}
					stackedSeries={stack}
					onDataPointSelection={(event, chartContext, config) => {
						let e = event.target;
						let index = e.getAttribute('j');
						let value = e.getAttribute('val');

						if (index && value && onColumnClick) {
							onColumnClick(
								index,
								value,
								chartContext.axes.w.config.series[config.seriesIndex].data[config.dataPointIndex]
							);
						}
					}}
					legends={{
						show: false,
					}}
					chartTooltip={chartTooltip}
					strokeCustomization={{
						type: ['smooth', 'smooth'],
						dash: [0, 16],
					}}
					fillCustomization={{
						type: ['solid', 'none'],
						opacity: [1, 1],
					}}
					colors={_getChartColors()}
					columnCustomization={{
						spacing: 0,
						radius: 5,
						columnWidth: getDynamicBarWidth(),
						barHeight: getDynamicBarHeight(),
						horizontal: !desktopView,
					}}
					xAxisCustomization={{
						tickAmount: desktopView ? 6 : 3,
						min: desktopView ? undefined : clampValues.min,
						max: desktopView ? undefined : clampValues.max,
						showLine: !desktopView,
						formatter: (value: any) => {
							return desktopView ? value : `${Math.round(value)} ${chartTooltip?.y?.suffix}`;
						},
					}}
					yAxisCustomization={{
						tickAmount: 4,
						min: desktopView ? clampValues.min : undefined,
						max: desktopView ? clampValues.max : undefined,
						showLine: desktopView,
						formatter: (value: any) => {
							return desktopView ? `${Math.round(value)} ${chartTooltip?.y?.suffix}` : value;
						},
						forceNiceScale: forceNiceScale,
					}}
				/>
			)}
		</ChartCard>
	);
};
