import React, { useEffect, useState } from 'react';
import {
	IComponent,
	ContentGrid,
	Text,
	IconType,
	getCounterTheme,
	StyleGrid,
} from '@fjordkraft/fjordkraft.component.library';
import { MS_ButtonTemplate, h4TextPrefab, paragraphTextPrefab } from '../../Prefabs';
import { Constants } from '../../data';
import { MSRichText, Card, ActionButton, MsButton } from '../../components';
import { useApplicationCoreDataContext, useApplicationServicehandlerContext } from '../../contexts';
import { useAuth } from 'react-oidc-context';
import { ICustomer, IHomePage } from '../../models';
import { HomePageData, currentElectricityCustomerNumber, getText, isImpersonating } from '../../services';
import classNames from 'classnames';
import './CustomerServiceInformationBlock.scss';
import { Navigate, useNavigate } from 'react-router';
import { useApplicationGuestsAndHostsContext } from '../../contexts/variations/ApplicationGuestsAndHostsContext';

interface ICustomerServiceInformationBlock extends IComponent {
	closeText: string;
	title: string;
	description: string;
	customerNumberLabel: string;
	customerNumber: string;
	nameLabel: string;
	name: string;
	logOutText: string;
}

interface IBlockDataSetup {
	user: any;
	userData: ICustomer;
	translations: IHomePage;
}

export const CustomerServiceInformationBlock = (props: IComponent) => {
	// ************************************
	// Properties
	// ************************************
	const { id, className, theme = 'Light', brand = Constants.uiBrand } = props;
	const classPrefix = 'userData-service-information-block';
	const { epiChildren } = useApplicationCoreDataContext();
	const { customerServiceFeature, setCustomerServiceFeature } = useApplicationServicehandlerContext();
	const { mainUser } = useApplicationGuestsAndHostsContext();
	const { user, signoutRedirect } = useAuth();
	const navigate = useNavigate();

	// ************************************
	// Lifecycle
	// ************************************

	const [blockData, setBlockData] = useState<ICustomerServiceInformationBlock>();

	useEffect(() => {
		if (epiChildren && customerServiceFeature && mainUser) {
			_setupBlockData({
				user,
				userData: mainUser,
				translations: HomePageData(epiChildren),
			});
		}
	}, [user, epiChildren, mainUser, customerServiceFeature]);

	// ************************************
	// Handlers
	// ************************************

	const _setupBlockData = (config: IBlockDataSetup) => {
		const { user, translations, userData } = config;
		setBlockData({
			theme: theme,
			brand: brand,
			closeText: getText('customerServiceBlockCloseButton', translations),
			title: getText('customerServiceBlockTitle', translations),
			description: getText('customerServiceBlockDesc', translations),
			customerNumber: currentElectricityCustomerNumber(user) as string,
			customerNumberLabel: getText('customerServiceBlockNumber', translations),
			nameLabel: getText('customerServiceBlockName', translations),
			name: `${userData?.firstName} ${userData?.lastName}`,
			logOutText: getText('customerServiceBlockLogout', translations),
		});
	};

	// ************************************
	// Render
	// ************************************
	return (
		<>
			{blockData && (
				<StyleGrid
					alignment="center"
					direction="column"
					className={classNames(`${classPrefix}`, {
						[`${className}`]: className,
					})}
				>
					<StyleGrid
						className={`${classPrefix}__content__close-button`}
						alignment="center-right"
						direction="row"
						gap={2}
					>
						<MsButton
							className={`${classPrefix}__content__close-button__button`}
							action={{
								onClick: () => {
									setCustomerServiceFeature(false);
									navigate('/');
								},
								onHoverEnter: () => { },
							}}
						>
							<Text
								{...paragraphTextPrefab(props)}
								type={'p'}
								style={'italic'}
								size={'small'}
							>
								{blockData.closeText}
							</Text>
							<Text
								{...paragraphTextPrefab(props)}
								size={'large'}
							>
								✖
							</Text>
						</MsButton>
					</StyleGrid>
					<Card
						id={id}
						alignment={'top-left'}
						direction={'column'}
						brand={blockData.brand}
						theme={blockData.theme}
					>
						<ContentGrid
							alignment={'center'}
							direction={'column'}
							tagType={'section'}
							gap={2}
						>
							<Text
								{...h4TextPrefab(props)}
								brand={brand}
								theme={getCounterTheme(theme)}
							>
								{blockData.title}
							</Text>
							<MSRichText
								className={`${classPrefix}__content__description`}
								text={blockData.description}
								brand={brand}
								theme={getCounterTheme(theme)}
							/>
							<Text
								{...paragraphTextPrefab(props)}
								brand={brand}
								theme={getCounterTheme(theme)}
							>
								<strong>{`${blockData.customerNumberLabel}:`}</strong> {`${blockData.customerNumber}`}
							</Text>
							<Text
								{...paragraphTextPrefab(props)}
								brand={brand}
								theme={getCounterTheme(theme)}
							>
								<strong>{`${blockData.nameLabel}:`}</strong> {`${blockData.name}`}
							</Text>
							<ActionButton
								template={MS_ButtonTemplate(theme, 'secondary')}
								brand={brand}
								padding="medium"
								action={{
									icon: IconType.LogoutIcon,
									text: blockData.logOutText,
									onClick: () => {
										signoutRedirect();
									},
								}}
							/>
						</ContentGrid>
					</Card>
				</StyleGrid>
			)}
		</>
	);
};
