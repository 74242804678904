import React from 'react';
import { IComponent, StyleGrid } from "@fjordkraft/fjordkraft.component.library";
import classNames from 'classnames';
import './HomePageGraphicBlock.scss';
import {GetGraphicPalette} from "./GraphicPalette";

export interface IHomePageGraphicBlockPalette {
    shade1: string;
    shade2: string;
    shade3: string;
    shade4: string;
    shade5: string;
    shade6: string;
    shade7: string;
    shade8: string;
    shade9: string;
    shade10: string;
    // Vinduer
    shade11: string;
    shade12: string;
    // Vindu 2
    shade13: string;
    // Gjenskinn vindu 2
    shade14: string;
    // Gjenskinn / skygge vindu 1
    shade15: string;
}

export interface IHomePageGraphicBlock extends Omit<IComponent, 'palette'> {
    palette?: IHomePageGraphicBlockPalette;
}

export const HomePageGraphicBlock = (props: IHomePageGraphicBlock) => {
    // ************************************
    // Properties
    // ************************************

    const {
        id,
        className,
        theme,
        brand,
        palette = GetGraphicPalette(brand)
        //{
        //     shade1: "#FF5500",
        //     shade2: "#fff",
        //     shade3: "#D33E00",
        //     shade4: "#F5AA81",
        //     shade5: "#D9D9D9",
        //     shade6: "#FF712B",
        //     shade7: "#F8D0BA",
        //     shade8: "#A82C00",
        //     shade9: "#7C1A00",
        //     shade10: "#FFEEE6"
        // }
    } = props;

    const classPrefix = 'home-page-banner-block';

    // ************************************
    // Render Finctionality
    // ************************************

    const _renderBanner = () => {
        return (
            <svg width="1440" height="380" viewBox="0 0 1440 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_9743_194218)">
                    <ellipse cx="550.584" cy="56.5944" rx="30.3182" ry="30.4833" fill={palette.shade1} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M569.275 85.2861C567.445 85.2861 565.788 86.0333 564.575 87.2423C564.591 87.032 564.607 86.8218 564.607 86.6074C564.607 82.0339 560.97 78.3266 556.484 78.3266C555.717 78.3266 554.978 78.4422 554.275 78.6446C552.596 74.7902 548.811 72.101 544.407 72.101C542.958 72.101 541.576 72.3942 540.313 72.9229C537.538 69.895 533.589 68 529.204 68C520.807 68 514 74.9393 514 83.4998C514 92.0603 520.807 99 529.204 99C529.217 99 529.229 98.9988 529.242 98.9988H569.275C572.974 98.9988 575.972 95.9589 576 92.1961V92.1425C576 88.355 572.99 85.2861 569.275 85.2861Z" fill={palette.shade2} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M690.515 36.959C688.479 36.959 686.635 37.7785 685.285 39.1044C685.303 38.8738 685.32 38.6433 685.32 38.4082C685.32 33.3921 681.273 29.3259 676.28 29.3259C675.427 29.3259 674.605 29.4528 673.822 29.6747C671.954 25.4473 667.741 22.4978 662.84 22.4978C661.227 22.4978 659.689 22.8195 658.283 23.3993C655.195 20.0784 650.801 18 645.92 18C636.575 18 629 25.6109 629 34.9998C629 44.3887 636.575 52 645.92 52C645.935 52 645.949 51.9986 645.963 51.9986H690.515C694.632 51.9986 697.968 48.6646 698 44.5377V44.4788C698 40.3248 694.65 36.959 690.515 36.959Z" fill={palette.shade2} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M946.515 36.959C944.479 36.959 942.635 37.7785 941.285 39.1044C941.303 38.8738 941.32 38.6433 941.32 38.4082C941.32 33.3921 937.273 29.3259 932.28 29.3259C931.427 29.3259 930.605 29.4528 929.822 29.6747C927.954 25.4473 923.741 22.4978 918.84 22.4978C917.227 22.4978 915.689 22.8195 914.283 23.3993C911.195 20.0784 906.801 18 901.92 18C892.575 18 885 25.6109 885 34.9998C885 44.3887 892.575 52 901.92 52C901.935 52 901.949 51.9986 901.963 51.9986H946.515C950.632 51.9986 953.968 48.6646 954 44.5377V44.4788C954 40.3248 950.65 36.959 946.515 36.959Z" fill={palette.shade2} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M850.671 73.4199C848.133 73.4199 845.835 74.4322 844.153 76.0702C844.174 75.7853 844.196 75.5005 844.196 75.2101C844.196 69.0137 839.152 63.9909 832.929 63.9909C831.866 63.9909 830.841 64.1475 829.866 64.4217C827.536 59.1997 822.286 55.5562 816.178 55.5562C814.167 55.5562 812.25 55.9535 810.498 56.6697C806.649 52.5674 801.172 50 795.089 50C783.442 50 774 59.4017 774 70.9997C774 82.5978 783.442 92 795.089 92C795.107 92 795.124 91.9983 795.142 91.9983H850.671C855.803 91.9983 859.961 87.8797 860 82.7819V82.7091C860 77.5777 855.825 73.4199 850.671 73.4199Z" fill={palette.shade2} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M1106.67 64.4199C1104.13 64.4199 1101.83 65.4322 1100.15 67.0702C1100.17 66.7853 1100.2 66.5005 1100.2 66.2101C1100.2 60.0137 1095.15 54.9909 1088.93 54.9909C1087.87 54.9909 1086.84 55.1475 1085.87 55.4217C1083.54 50.1997 1078.29 46.5562 1072.18 46.5562C1070.17 46.5562 1068.25 46.9535 1066.5 47.6697C1062.65 43.5674 1057.17 41 1051.09 41C1039.44 41 1030 50.4017 1030 61.9997C1030 73.5978 1039.44 83 1051.09 83C1051.11 83 1051.12 82.9983 1051.14 82.9983H1106.67C1111.8 82.9983 1115.96 78.8797 1116 73.7819V73.7091C1116 68.5777 1111.82 64.4199 1106.67 64.4199Z" fill={palette.shade2} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M406.671 54.4199C404.133 54.4199 401.835 55.4322 400.153 57.0702C400.174 56.7853 400.196 56.5005 400.196 56.2101C400.196 50.0137 395.152 44.9909 388.929 44.9909C387.866 44.9909 386.841 45.1475 385.866 45.4217C383.536 40.1997 378.286 36.5562 372.178 36.5562C370.167 36.5562 368.25 36.9535 366.498 37.6697C362.649 33.5674 357.172 31 351.089 31C339.442 31 330 40.4017 330 51.9997C330 63.5978 339.442 73 351.089 73C351.107 73 351.124 72.9983 351.142 72.9983H406.671C411.803 72.9983 415.961 68.8797 416 63.7819V63.7091C416 58.5777 411.825 54.4199 406.671 54.4199Z" fill={palette.shade2} />
                </g>
                <g opacity="0.6">
                    <path d="M949.531 145.16C949.411 142.83 948.661 140 946.501 137C940.641 128.85 934.501 119.7 934.501 119.7L925.711 127.51L939.501 141L949.531 145.16Z" fill={palette.shade3} />
                    <path d="M432.003 126L457.503 151.5L473.003 159.5L545.003 108.5L547.343 105.97L539.503 98.5L478.503 146L454.143 119.71L447.273 126.75L434.643 113.1L418.133 127.42L423.503 133L432.003 126Z" fill={palette.shade3} />
                    <path d="M602.999 120.5L613.499 110.5L661.129 141.36L699.499 168.5H801.999L840.629 136.31L840.619 136.29L840.639 136.3L857.339 122.38L850.399 118.16L840.509 125.7L825.289 114.14L773.399 162.45L711.079 168.45L667.999 118L659.999 126.75L631.999 106.5L623.999 114.14L608.359 98.5L595.499 111L587.999 102.58L585.289 104.79L589.499 106.5L602.999 120.5Z" fill={palette.shade3} />
                    <path d="M1017.74 123.86L1006.5 117.5L983.001 134L977.501 128.2L953.711 146.9L972.001 154.5L1017.74 123.86Z" fill={palette.shade3} />
                </g>
                <g opacity="0.8">
                    <path d="M420.617 149.29L413.617 141.29L423.497 133L397.997 106.5L397.847 106.64L396.617 118.29L405.617 127.29L381.617 136.29L375.617 142.29L402.957 132.29L405.617 144.19L400.617 145.29L423.617 168.29L447.377 177.5L429.617 147.29L420.617 149.29Z" fill={palette.shade3} />
                    <path d="M566.617 113.29L585.287 123.48L579.617 145.29L561.617 153.29L599.617 146.29L623.377 159.5L611.617 125.29L602.997 120.5L589.497 106.5L575.997 101L563.997 88L572.557 106.5L566.617 113.29Z" fill={palette.shade3} />
                    <path d="M661.13 141.36L613.5 110.5L661.13 141.36Z" fill={palette.shade3} />
                    <path d="M626.62 122.29L629.05 136.02L631.35 129.14L637.75 132.72L649.62 137.29L661.13 141.36L613.5 110.5L618.42 119.55L626.62 122.29Z" fill={palette.shade3} />
                    <path d="M892.562 133.5L911.622 140.29L914.622 147.29L928.323 145.16L903.003 127.5L893.503 116.5L898.203 129L892.562 133.5Z" fill={palette.shade3} />
                    <path d="M888 120L877 106V125.36L888 120Z" fill={palette.shade3} />
                    <path d="M1033.72 136.14L1037.62 161.29L1059.62 171.29L1043.03 153.36L1063.73 146.29L1097.62 161.29L1063.47 140.29L1042.77 141.14L1037 124L1026.5 118L1016.04 144.72L1033.72 136.14Z" fill={palette.shade3} />
                    <path d="M1062.62 130.29L1073.62 137.29L1064.5 127.5L1053 112.5L1052.93 122.16L1062.62 130.29Z" fill={palette.shade3} />
                    <path d="M840.617 136.29L840.627 136.31L840.637 136.3L840.617 136.29Z" fill={palette.shade3} />
                    <path d="M559.797 121.74L557.207 126.06L544.257 123.47L533.617 120.29L545.977 132.97L554.617 131.24L562.387 132.97L570.167 137.29L564.977 130.38L559.797 121.74Z" fill={palette.shade3} />
                    <path d="M865.965 135.72L852.635 127.3L852.625 127.31L856.925 138.29L872.615 143.29L894.495 147.24L877.615 135.29L865.965 135.72Z" fill={palette.shade3} />
                    <path d="M559.797 121.74L557.207 126.06L544.257 123.47L533.617 120.29L545.977 132.97L554.617 131.24L562.387 132.97L570.167 137.29L564.977 130.38L559.797 121.74Z" fill={palette.shade3} />
                    <path d="M346.62 154.29L366.62 153.29L341.18 144.66L346.62 154.29Z" fill={palette.shade3} />
                </g>
                <path d="M400.62 145.29L405.62 144.19L402.96 132.29L375.62 142.29L381.62 136.29L405.62 127.29L396.62 118.29L397.85 106.64L398 106.5L376 126L368 119.5L302 177.5H447.38L423.62 168.29L400.62 145.29ZM346.62 154.29L341.18 144.66L366.62 153.29L346.62 154.29Z" fill={palette.shade3} />
                <path d="M1096 147.5L1064.5 127.5L1073.62 137.29L1062.62 130.29L1052.93 122.17L1053 112.51L1037 124.01L1042.77 141.15L1063.48 140.29L1097.63 161.29L1063.74 146.29L1043.03 153.36L1059.62 171.29L1037.62 161.29L1033.72 136.15L1016.04 144.73L1026.5 118.01L972.003 154.51L939.503 141.01L916.003 118.01L903.003 127.51L928.323 145.17L914.623 147.29L911.623 140.29L892.563 133.5L898.213 129L893.513 116.5L888.013 120L877.013 125.36V106L802.013 168.5H699.513L661.143 141.36L649.633 137.29L637.763 132.73L631.353 129.15L629.053 136.03L626.633 122.29L618.433 119.56L613.513 110.51L603.013 120.51L611.633 125.3L623.393 159.51L599.633 146.3L561.633 153.3L579.633 145.3L585.303 123.5L566.633 113.3L572.573 106.51L564.013 88.0098L545.013 108.51L473.013 159.51L457.513 151.51L432.013 126.01L423.513 133.01L413.633 141.3L420.633 149.3L429.633 147.3L447.393 177.51H1120.51L1096.01 147.51L1096 147.5ZM562.393 132.97L554.623 131.24L545.983 132.97L533.623 120.29L544.263 123.47L557.213 126.06L559.803 121.74L564.983 130.38L570.163 137.29L562.393 132.97ZM872.623 143.29L856.933 138.29L852.633 127.31H852.643L865.973 135.72L877.623 135.28L894.503 147.23L872.623 143.28V143.29Z" fill={palette.shade3} />
                <path d="M319.955 166.761C272.517 177.923 281.885 217.617 315.5 233.5C349.116 249.383 383.614 271.93 377 305C365.5 362.5 439.813 402.226 510 365.5C553 343 593.5 333.5 649 330C720.482 325.492 767.499 340.5 840.5 333.5C913.5 326.5 941 266.239 1039.5 258.5C1109.5 253 1148.75 225.617 1148.75 187.817C1148.75 147.735 1098.49 145.198 1069.06 147.735C1039.63 150.271 1013.5 141.646 976.72 138.856C939.935 136.065 929.787 154.584 873.976 141.646C818.165 128.708 788.483 131.499 750.43 133.021C712.377 134.543 716.944 115.398 703.244 105.758C689.545 96.118 665.445 100.684 644.896 110.578C624.348 120.472 610.141 135.142 556.106 135.142C502.07 135.142 469 143.019 443 156C407.746 173.6 367.393 155.599 319.955 166.761Z" fill={palette.shade4} />
                <mask id="mask0_9743_194218" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="558" y="17" width="315" height="186">
                    <path d="M558 17.6191H873V165.119C873 165.119 779.751 164.619 720 164.619C716.5 164.619 712 166.077 706.5 171.119C694.5 182.119 672.5 202.619 672.5 202.619H558V17.6191Z" fill={palette.shade5} />
                </mask>
                <g mask="url(#mask0_9743_194218)">
                    <g clipPath="url(#clip1_9743_194218)">
                        <g clipPath="url(#clip2_9743_194218)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M703.301 130.378L681.086 108.163H703.301V130.378Z" fill={palette.shade6} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M610.234 156.201H676.58V105.303H610.234V156.201Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M610.234 105.303H676.58L705.496 84.2671H639.15L610.234 105.303Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M812.969 156.263H815.263V117.782H812.969V156.263Z" fill={palette.shade2} />
                            <path d="M615.024 156.201H614.164V105.303H615.024V156.201Z" fill={palette.shade8} />
                            <path d="M618.925 156.201H619.785V105.303H618.925V156.201Z" fill={palette.shade8} />
                            <path d="M624.547 156.201H623.687V105.303H624.547V156.201Z" fill={palette.shade8} />
                            <path d="M628.449 156.201H629.309V105.303H628.449V156.201Z" fill={palette.shade8} />
                            <path d="M634.071 156.201H633.211V105.303H634.071V156.201Z" fill={palette.shade8} />
                            <path d="M637.973 156.201H638.833V105.303H637.973V156.201Z" fill={palette.shade8} />
                            <path d="M643.594 156.201H642.734V105.303H643.594V156.201Z" fill={palette.shade8} />
                            <path d="M647.496 156.201H648.356V105.303H647.496V156.201Z" fill={palette.shade8} />
                            <path d="M653.118 156.201H652.258V105.303H653.118V156.201Z" fill={palette.shade8} />
                            <path d="M657.02 156.201H657.88V105.303H657.02V156.201Z" fill={palette.shade8} />
                            <path d="M662.641 156.201H661.782V105.303H662.641V156.201Z" fill={palette.shade8} />
                            <path d="M666.543 156.201H667.403V105.303H666.543V156.201Z" fill={palette.shade8} />
                            <path d="M671.305 156.201H672.165V105.303H671.305V156.201Z" fill={palette.shade8} />
                            <path d="M615.024 105.303H614.164L642.305 84.2671H643.164L615.024 105.303Z" fill={palette.shade6} />
                            <path d="M618.926 105.303H619.785L647.926 84.2671H647.066L618.926 105.303Z" fill={palette.shade6} />
                            <path d="M624.547 105.303H623.687L651.828 84.2671H652.688L624.547 105.303Z" fill={palette.shade6} />
                            <path d="M628.449 105.303H629.309L657.45 84.2671H656.59L628.449 105.303Z" fill={palette.shade6} />
                            <path d="M634.071 105.303H633.211L661.352 84.2671H662.211L634.071 105.303Z" fill={palette.shade6} />
                            <path d="M637.973 105.303H638.833L666.973 84.2671H666.114L637.973 105.303Z" fill={palette.shade6} />
                            <path d="M643.594 105.303H642.734L670.875 84.2671H671.735L643.594 105.303Z" fill={palette.shade6} />
                            <path d="M647.496 105.303H648.356L676.497 84.2671H675.637L647.496 105.303Z" fill={palette.shade6} />
                            <path d="M653.118 105.303H652.258L680.399 84.2671H681.258L653.118 105.303Z" fill={palette.shade6} />
                            <path d="M657.02 105.303H657.88L686.02 84.2671H685.16L657.02 105.303Z" fill={palette.shade6} />
                            <path d="M662.641 105.303H661.782L689.923 84.2671H690.782L662.641 105.303Z" fill={palette.shade6} />
                            <path d="M666.543 105.303H667.403L695.544 84.2671H694.684L666.543 105.303Z" fill={palette.shade6} />
                            <path d="M671.305 105.303H672.165L700.306 84.2671H699.446L671.305 105.303Z" fill={palette.shade6} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M621.867 206.658H709.804V163.31H621.867V206.658Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M620.156 156.201V163.31H621.862V205.014H792.932V156.201H709.799H620.156Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M621.867 165.493H752.896V163.311H621.867V165.493Z" fill={palette.shade4} />
                            <path d="M621.867 167.709H752.896V166.901H621.867V167.709Z" fill={palette.shade9} />
                            <path d="M621.867 171.423H720.063V170.615H621.867V171.423Z" fill={palette.shade9} />
                            <path d="M752.896 175.137H621.867V174.329H752.896V175.137Z" fill={palette.shade9} />
                            <path d="M621.867 178.851H707.54V178.043H621.867V178.851Z" fill={palette.shade9} />
                            <path d="M752.896 182.565H621.867V181.757H752.896V182.565Z" fill={palette.shade9} />
                            <path d="M621.867 186.28H752.896V185.472H621.867V186.28Z" fill={palette.shade9} />
                            <path d="M752.896 189.993H621.867V189.185H752.896V189.993Z" fill={palette.shade9} />
                            <path d="M621.867 193.708H752.896V192.9H621.867V193.708Z" fill={palette.shade9} />
                            <path d="M752.896 197.422H621.867V196.614H752.896V197.422Z" fill={palette.shade9} />
                            <path d="M621.867 201.136H752.896V200.328H621.867V201.136Z" fill={palette.shade9} />
                            <path d="M752.896 204.85H621.867V204.042H752.896V204.85Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M703.328 156.201H779.87V90.2754H703.328V156.201Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 156.201H812.96V117.782H779.867V156.201Z" fill={palette.shade4} />
                            <path d="M703.328 93.4156H779.87V92.7759H703.328V93.4156Z" fill={palette.shade8} />
                            <path d="M779.87 96.9578H703.328V96.3183H779.87V96.9578Z" fill={palette.shade8} />
                            <path d="M703.328 100.5H779.87V99.8601H703.328V100.5Z" fill={palette.shade8} />
                            <path d="M779.87 104.042H703.328V103.402H779.87V104.042Z" fill={palette.shade8} />
                            <path d="M703.328 107.584H779.87V106.945H703.328V107.584Z" fill={palette.shade8} />
                            <path d="M779.87 111.126H703.328V110.487H779.87V111.126Z" fill={palette.shade8} />
                            <path d="M703.328 114.668H779.87V114.029H703.328V114.668Z" fill={palette.shade8} />
                            <path d="M779.87 118.21H703.328V117.571H779.87V118.21Z" fill={palette.shade8} />
                            <path d="M703.328 121.962L812.962 121.962V121.322H703.328V121.962Z" fill={palette.shade8} />
                            <path d="M703.328 124.865V125.504L812.962 125.504V124.864L703.328 124.865Z" fill={palette.shade8} />
                            <path d="M703.328 129.046H812.962V128.406H703.328V129.046Z" fill={palette.shade8} />
                            <path d="M703.328 132.588V131.949H812.962V132.588H703.328Z" fill={palette.shade8} />
                            <path d="M703.328 136.13H812.962V135.491H703.328V136.13Z" fill={palette.shade8} />
                            <path d="M703.328 139.672H812.962V139.033H703.328V139.672Z" fill={palette.shade8} />
                            <path d="M703.328 143.214V142.575H812.962V143.214H703.328Z" fill={palette.shade8} />
                            <path d="M703.328 146.757V146.117H812.962V146.757H703.328Z" fill={palette.shade8} />
                            <path d="M703.328 157.39V156.751H812.962V157.39H703.328Z" fill={palette.shade8} />
                            <path d="M703.328 153.848H812.962V153.209H703.328V153.848Z" fill={palette.shade8} />
                            <path d="M703.328 150.306V149.666H812.962V150.306H703.328Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M752.891 169.129H767.094V156.201H752.891V169.129Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.102 156.201H778.116V127.186H715.102V156.201Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M766.023 127.187L778.119 143.216V139.519L768.813 127.187H766.023Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M739.57 127.187L761.464 156.201H762.858L740.965 127.187H739.57Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M730.898 127.187L752.792 156.201H755.664L733.771 127.187H730.898Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.102 133.645L732.121 156.201H733.516L715.102 131.797V133.645Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M771.461 127.187L778.118 136.008V132.312L774.25 127.187H771.461Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M743.852 127.187L765.745 156.201H772.578L750.685 127.187H743.852Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.102 145.448L723.216 156.201H730.049L715.102 136.392V145.448Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M713.805 127.186H767.319V125.898H713.805V127.186Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M713.805 156.201H715.099V126.542H713.805V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M713.352 156.201H714.646V125.898H713.352V156.201Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M778.578 156.201H779.872V125.898H778.578V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M778.117 156.201H779.411V125.898H778.117V156.201Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M711.07 156.201H713.346V125.898H711.07V156.201Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M781.492 125.898L799.044 149.2V129.627L796.235 125.898H781.492Z" fill={palette.shade13} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.868 156.201H780.146L779.868 155.832V156.201Z" fill={palette.shade3} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 152.865L782.38 156.201H786.34L779.867 147.607V152.865Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 146.417L787.236 156.201H799.041V155.758L779.867 130.224V146.417Z" fill={palette.shade13} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M799.043 126.66V125.898H798.469L799.043 126.66Z" fill={palette.shade14} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 129.063L799.041 154.569V152.167L779.867 126.71V129.063Z" fill={palette.shade14} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 130.224L799.041 155.758V154.569L779.867 129.063V130.224Z" fill={palette.shade14} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M796.234 125.898L799.043 129.627V126.66L798.469 125.898H796.234Z" fill={palette.shade14} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 125.898V126.711L799.041 152.167V149.2L781.489 125.898H779.867Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 147.607L786.34 156.201H787.236L779.867 146.417V147.607Z" fill={palette.shade14} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 155.831L780.146 156.201H782.38L779.867 152.865V155.831Z" fill={palette.shade14} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M788.961 156.201H790.255V126.542H788.961V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M799.047 156.201H800.341V126.542H799.047V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 127.186H800.795V125.898H779.867V127.186Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M779.867 134.828H800.795V133.539H779.867V134.828Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M789.422 156.201H790.716V126.542H789.422V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M799.508 156.201H800.802V126.542H799.508V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M800.797 156.201H802.993V125.898H800.797V156.201Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.101 135.345H729.769V134.828H714.641L715.101 135.345Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M730.234 156.201H731.529V126.542H730.234V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M729.773 156.201H731.068V125.898H729.773V156.201Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M731.522 135.345H746.191V134.828H731.062L731.522 135.345Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M746.656 156.201H747.95V126.542H746.656V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M746.195 156.201H747.489V125.898H746.195V156.201Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M747.944 135.345H762.613V134.828H747.484L747.944 135.345Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M763.078 156.201H764.372V126.542H763.078V156.201Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M762.617 156.201H763.911V125.898H762.617V156.201Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M764.366 135.345H778.112V134.828H763.906L764.366 135.345Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.101 130.734H729.769V130.218H714.641L715.101 130.734Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M731.522 130.734H746.191V130.218H731.062L731.522 130.734Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M747.944 130.734H762.613V130.218H747.484L747.944 130.734Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M764.366 130.734H778.112V130.218H763.906L764.366 130.734Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.102 156.201H729.77V134.828H715.102V156.201Z" fill={palette.shade12} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.102 156.201H723.216V134.828H715.102V156.201Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M723.216 138.64V134.828H715.102L723.216 138.64Z" fill={palette.shade15} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M713.352 127.186H779.414V125.898H713.352V127.186Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M713.352 134.828H778.119V133.539H713.352V134.828Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M682.922 156.46H703.573V109.799H682.922V156.46Z" fill={palette.shade10} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M703.573 130.493L682.875 109.861H703.573V130.493Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M682.922 109.799H703.573L735.541 86.4434H714.889L682.922 109.799Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M759.253 118.001L815.527 118.064V156.522L759.253 156.46V118.001ZM753.148 163.642L819.967 163.704V111.854L753.148 111.791V163.642Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M747.034 156.46H682.925V109.799L714.893 86.4432L747.034 109.799V156.46ZM676.82 105.509V163.642H753.148V105.509L715.061 77.666L676.82 105.509Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M804.766 147.572H839.939V146.291H804.766V147.572Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M813.977 147.572L820.834 156.46H821.638L814.78 147.572H813.977Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M829.211 147.572L836.069 156.46H836.872L830.014 147.572H829.211Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M805.836 147.572L812.694 156.46H813.497L806.639 147.572H805.836Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M826.422 147.572L833.28 156.46H835.12L828.262 147.572H826.422Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M804.766 149.559L810.09 156.46H810.894L804.766 148.518V149.559Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M804.766 154.686L806.135 156.46H808.903L804.766 151.098V154.686Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M838.883 156.46H839.937V147.572H838.883V156.46Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M804.766 156.46H805.82V147.572H804.766V156.46Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M804.766 146.612H839.939V146.291H804.766V146.612Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M645.998 125.501H643.828L646.17 128.272H672.487H674.486V126.98L673.255 125.501H645.998Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M651.695 116.098L659.947 126.792H668.016L659.765 116.098H651.695Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M649.461 116.098L657.712 126.792H658.94L650.688 116.098H649.461Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M643.82 126.791H645.878L643.82 124.124V126.791Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M673.256 123.244V116.098H667.742L673.256 123.244Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M662.273 116.098L670.525 126.792H673.249V124.55L666.727 116.098H662.273Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M643.82 116.098V122.818L646.886 126.792H656.706L648.455 116.098H643.82Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M666.734 116.098L673.256 124.55V123.244L667.742 116.098H666.734Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M643.82 124.124L645.878 126.792H646.886L643.82 122.818V124.124Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M648.453 116.098L656.704 126.792H657.712L649.461 116.098H648.453Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M650.688 116.098L658.939 126.792H659.946L651.695 116.098H650.688Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M659.766 116.098L668.017 126.792H670.53L662.279 116.098H659.766Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M642.461 126.792H672.064V125.501H642.461V126.792Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M672.07 126.792H673.256V125.501H672.07V126.792Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M643.82 125.501H646.179V116.098H643.82V125.501Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M642.461 125.824H672.064V125.501H642.461V125.824Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M646.179 118.629H673.256V116.098H643.82L646.179 118.629Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M617.928 142.59H615.758L618.1 145.361H644.417H646.416V144.069L645.185 142.59H617.928Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M623.633 133.187L631.884 143.88H639.954L631.703 133.187H623.633Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M621.398 133.187L629.65 143.88H630.877L622.626 133.187H621.398Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M615.75 143.88H617.808L615.75 141.213V143.88Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M645.186 140.333V133.187H639.672L645.186 140.333Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M634.211 133.187L642.462 143.88H645.186V141.639L638.664 133.187H634.211Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M615.75 133.187V139.907L618.816 143.88H628.636L620.385 133.187H615.75Z" fill={palette.shade1} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M638.664 133.187L645.186 141.639V140.333L639.672 133.187H638.664Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M615.75 141.213L617.808 143.88H618.816L615.75 139.907V141.213Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M620.391 133.187L628.642 143.88H629.649L621.398 133.187H620.391Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M622.625 133.187L630.876 143.88H631.884L623.633 133.187H622.625Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M631.703 133.187L639.954 143.88H642.467L634.216 133.187H631.703Z" fill={palette.shade11} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M614.398 143.88H644.002V142.59H614.398V143.88Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M644 143.88H645.186V142.59H644V143.88Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M615.75 142.59H618.109V133.187H615.75V142.59Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M614.398 142.913H644.002V142.59H614.398V142.913Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M618.109 135.718H645.186V133.187H615.75L618.109 135.718Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M684.344 147.572H747.032V146.612H684.344V147.572Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M684.344 146.612H747.032V146.291H684.344V146.612Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M693.781 147.572L701.362 156.46H702.166L694.585 147.572H693.781Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M685.414 147.572L692.995 156.46H693.798L686.218 147.572H685.414Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M738.539 147.572L746.12 156.46H746.924L739.343 147.572H738.539Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M735.758 147.572L743.339 156.46H745.179L737.598 147.572H735.758Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M709.484 147.572L717.065 156.46H718.906L711.325 147.572H709.484Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M684.344 149.559L690.392 156.46H691.195L684.344 148.518V149.559Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M684.344 154.256L686.436 156.46H689.205L684.344 151.098V154.256Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M682.922 157.33H747.031V156.46H682.922V157.33Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M759.211 157.33H767.355V156.46H759.211V157.33Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M777.914 168.385H833.403V163.577H777.914V168.385Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M753.148 112.84H820.033V105.509H753.148V112.84Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M715.062 77.666L753.15 105.509H820.034L781.794 77.666H715.062Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M717.922 77.666L758.75 107.413H820.089L779.108 77.666H717.922Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M756.141 108.365L758.754 110.269V107.413L756.141 105.509V108.365Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M758.75 110.269H820.089V107.413H758.75V110.269Z" fill={palette.shade8} />
                            <path d="M762.582 107.413L722.648 77.666H723.511L763.445 107.413H762.582Z" fill={palette.shade8} />
                            <path d="M767.36 107.413L727.426 77.666H728.289L768.223 107.413H767.36Z" fill={palette.shade8} />
                            <path d="M732.205 77.666L772.139 107.413H773.002L733.067 77.666H732.205Z" fill={palette.shade8} />
                            <path d="M776.917 107.413L736.983 77.666H737.845L777.78 107.413H776.917Z" fill={palette.shade8} />
                            <path d="M741.761 77.666L781.695 107.413H782.558L742.623 77.666H741.761Z" fill={palette.shade8} />
                            <path d="M786.473 107.413L746.539 77.666H747.401L787.336 107.413H786.473Z" fill={palette.shade8} />
                            <path d="M751.317 77.666L791.251 107.413H792.114L752.18 77.666H751.317Z" fill={palette.shade8} />
                            <path d="M796.029 107.413L756.095 77.666H756.958L796.892 107.413H796.029Z" fill={palette.shade8} />
                            <path d="M760.873 77.666L800.807 107.413H801.67L761.736 77.666H760.873Z" fill={palette.shade8} />
                            <path d="M805.585 107.413L765.651 77.666H766.514L806.448 107.413H805.585Z" fill={palette.shade8} />
                            <path d="M770.429 77.666L810.363 107.413H811.226L771.292 77.666H770.429Z" fill={palette.shade8} />
                            <path d="M815.141 107.413L775.207 77.666H776.07L816.004 107.413H815.141Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M763.441 110.269H762.578V107.413H763.441V110.269ZM768.219 110.269H767.356V107.413H768.219V110.269ZM772.133 110.269H772.995V107.413H772.133V110.269ZM777.775 110.269H776.912V107.413H777.775V110.269ZM781.691 110.269H782.553V107.413H781.691V110.269ZM787.329 110.269H786.467V107.413H787.329V110.269ZM791.246 110.269H792.109V107.413H791.246V110.269ZM796.888 110.269H796.025V107.413H796.888V110.269ZM800.801 110.269H801.664V107.413H800.801V110.269ZM806.443 110.269H805.58V107.413H806.443V110.269ZM810.359 110.269H811.222V107.413H810.359V110.269ZM815.998 110.269H815.135V107.413H815.998V110.269Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M610.461 157.33H676.819V156.46H610.461V157.33Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M610.461 163.577H676.819V162.706H610.461V163.577Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M612.164 162.706H676.819V157.331H612.164V162.706Z" fill={palette.shade6} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M612.164 158.931H676.819V157.331H612.164V158.931Z" fill={palette.shade4} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M663.219 162.706H663.566V157.343H663.219V162.706Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M645.016 162.7H645.362V157.337H645.016V162.7Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M627.25 162.7H627.597V157.337H627.25V162.7Z" fill={palette.shade8} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M610.461 162.7H676.819V162.483H610.461V162.7Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M795.828 157.33H842.083V156.46H795.828V157.33Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M795.828 163.577H842.083V162.706H795.828V163.577Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M794.383 162.706H842.088V157.331H794.383V162.706Z" fill={palette.shade6} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M828.484 162.706H828.831V157.343H828.484V162.706Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M810.281 162.7H810.628V157.337H810.281V162.7Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M792.328 157.33H795.833V156.46H792.328V157.33Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M792.328 163.577H795.833V162.706H792.328V163.577Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M793.734 162.706H794.382V157.331H793.734V162.706Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M785.055 168.385H796.627V156.46H785.055V168.385Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M759.211 159.695H796.627V156.46H759.211V159.695Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M755.352 162.592H792.767V159.695H755.352V162.592Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M751.492 165.489H788.908V162.592H751.492V165.489Z" fill={palette.shade2} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M747.641 168.385H785.056V165.489H747.641V168.385Z" fill={palette.shade7} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M759.211 157.33H796.627V156.46H759.211V157.33Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M755.352 160.566H792.767V159.696H755.352V160.566Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M751.492 163.463H788.908V162.592H751.492V163.463Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M747.641 166.359H785.056V165.489H747.641V166.359Z" fill={palette.shade9} />
                            <path fillRule="evenodd" clipRule="evenodd" d="M733.562 169.435H862.23V167.568H733.562V169.435Z" fill={palette.shade2} />
                        </g>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_9743_194218">
                        <rect width="860" height="187" fill={palette.shade2} transform="translate(289 18)" />
                    </clipPath>
                    <clipPath id="clip1_9743_194218">
                        <rect width="315" height="187.031" fill={palette.shade2} transform="translate(562 17.6191)" />
                    </clipPath>
                    <clipPath id="clip2_9743_194218">
                        <rect width="315" height="126.984" fill={palette.shade2} transform="translate(562 77.666)" />
                    </clipPath>
                </defs>
            </svg>
        );
    }

    // ************************************
    // Render
    // ************************************

    return (
        <StyleGrid
            id={id}
            className={classNames(classPrefix, {
                [`${className}`]: className
            })}
            direction="column"
            alignment="center"
            boxSizing="border-box"
            wrap={false}
        >
            {_renderBanner()}
        </StyleGrid>
    );
}