import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import {
	currentElectricityCustomerNumber,
	handleAsyncData,
	IResponse,
	isImpersonating,
} from '../../../services';
import { ApplicationServicehandlerContext } from '../../../contexts/variations/ApplicationServicehandlerContext';
import { fetchBlob } from '@fjordkraft/fjordkraft.component.library';
import { Constants } from '../../../data';
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext';

export interface IServiceForm {
	method: 'POST' | 'GET';
	url: string;
	fields: { value: string; name: string }[];
}

const REQUEST_CAHCE: Map<string, any> = new Map<string, any>();
const GET_DATA_CACHE: Map<string, any> = new Map<string, any>();

export function ApplicationServiceWrapper(props: any) {
	// ************************************
	// Properties
	// ************************************

	const { user } = useAuth();
	const { hostIdForCustomerDataRequests } = useApplicationGuestsAndHostsContext();

	// ************************************
	// Cache handling
	// ************************************

	const clearCache = () => {
		GET_DATA_CACHE.clear();
		cacheCleared();
	};

	const cacheCleared = () => {
		return true;
	};

	// ************************************
	// Lifecycle
	// ************************************

	const [token, _setToken] = useState<string | undefined>(user?.access_token);

	// Internal / userData service feature (Can be used for prod testing)
	const [customerServiceFeature, setCustomerServiceFeature] = useState<boolean>(
		!!(isImpersonating(user) && currentElectricityCustomerNumber(user))
	);

	useEffect(() => {
		if (user?.access_token) {
			_setToken(user.access_token);
		}
	}, [user?.access_token]);

	const contextData = useMemo(() => {
		let tok: string = token ?? '';

		return {
			GET: (additions: string, refetch = false) => {
				return _GET(additions, tok, refetch);
			},
			GETTYPED: (additions: string, refetch = false) => {
				return _GET(additions, tok, refetch);
			},
			POST: (additions: string, body: any) => {
				return _POST(additions, tok, JSON.stringify(body));
			},
			POSTTYPED: (additions: string, body: any) => {
				return _POST(additions, tok, JSON.stringify(body));
			},
			PUT: (additions: string, body: any) => {
				return _PUT(additions, tok, JSON.stringify(body));
			},
			PUTTYPED: (additions: string, body: any) => {
				return _PUT(additions, tok, JSON.stringify(body));
			},
			DELETE: (additions: string, body: any) => {
				return _DELETE(additions, tok, JSON.stringify(body));
			},
			PATCH: (additions: string, body: any) => {
				return _PATCH(additions, tok, JSON.stringify(body));
			},
			BLOB: (additions: string, name?: string) => {
				return _BLOB(additions, tok, name);
			},
			FORMPOST: (url: string, fields: { value: string; name: string }[]) => {
				return _FORMPOST(url, fields);
			},
			token: tok,
			customerServiceFeature,
			setCustomerServiceFeature,
			user,
			clearCache,
			cacheCleared,
		};
	}, [token, user, customerServiceFeature, hostIdForCustomerDataRequests]);

	// ************************************
	// Handling
	// ************************************

	const _GET = async (
		additions: string,
		token: string,
		refetch: boolean,
		abortController?: AbortController
	): Promise<IResponse> => {
		const cacheKey = hostIdForCustomerDataRequests ? `${hostIdForCustomerDataRequests}:${additions}` : additions;
		if (!refetch && GET_DATA_CACHE.has(cacheKey)) {
			return GET_DATA_CACHE.get(cacheKey);
		} else if (REQUEST_CAHCE.has(cacheKey)) {
			return await REQUEST_CAHCE.get(cacheKey).then((response: IResponse) => {
				return response;
			});
		} else {
			let promise = handleAsyncData({
				token,
				additions,
				method: 'GET',
				abortController: abortController,
				hostIdForCustomerDataRequests,
			})
				.then((response: IResponse) => {
					GET_DATA_CACHE.set(cacheKey, response);
					return response;
				})
				.finally(() => {
					REQUEST_CAHCE.delete(cacheKey);
				});

			REQUEST_CAHCE.set(cacheKey, promise);
			return await promise;
		}
	};

	const _POST = async (additions: string, token: string, body: any) => {
		let resp = await handleAsyncData({
			token,
			additions,
			method: 'POST',
			body,
		});
		return resp;
	};

	const _PUT = async (additions: string, token: string, body: any) => {
		let resp = await handleAsyncData({
			token,
			additions,
			method: 'PUT',
			body,
		});
		return resp;
	};

	const _DELETE = async (additions: string, token: string, body: any) => {
		let resp = await handleAsyncData({
			token,
			additions,
			method: 'DELETE',
			body,
		});
		return resp;
	};

	const _PATCH = async (additions: string, token: string, body: any) => {
		let resp = await handleAsyncData({
			token,
			additions,
			method: 'PATCH',
			body,
		});
		return resp;
	};

	const _BLOB = async (additions: string, token: string, name?: string) => {
		let { url, version, brand } = Constants.api;
		let query = `${url}/${version}/${brand}/${additions}`;
		let resp = await fetchBlob(
			query,
			name,
			token,
			undefined,
			hostIdForCustomerDataRequests ? [{ name: 'x-host-id', value: hostIdForCustomerDataRequests }] : undefined
		);
		return resp;
	};

	const _FORMPOST = async (url: string, fields: { value: string; name: string }[]) => {
		let tempForm = document.createElement('form') as HTMLFormElement;

		document.body.appendChild(tempForm);
		tempForm.hidden = true;
		tempForm.action = url;
		tempForm.method = 'POST';
		tempForm.name = 'minSideFormPOST';

		fields.forEach((field: { value: string; name: string }) => {
			let newInput = document.createElement('input') as HTMLInputElement;
			newInput.readOnly = true;
			newInput.hidden = true;
			newInput.value = field.value;
			newInput.name = field.name;
			tempForm.appendChild(newInput);
		});

		tempForm.submit();
		tempForm.remove();
	};

	// ************************************
	// Render
	// ************************************

	return (
		<>
			{token && (
				<ApplicationServicehandlerContext.Provider value={contextData}>
					{props.children}
				</ApplicationServicehandlerContext.Provider>
			)}
		</>
	);
}