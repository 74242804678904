import { IAction, IconType, IImage, IOption } from '@fjordkraft/fjordkraft.component.library';
import { IDetailsDescriptionBlock, IHighlightedDropdownBlock, IPlankHouse, ISectionBlock } from '../../../blocks';
import {
	IAddonData,
	ICustomer,
	ICustomerAccountInformation,
	ICustomerInstallation,
	IDefaultProps,
	IServicePage,
	ServiceStatus,
} from '../../../models';
import { IServiceBasePageView } from './ServiceBasePage';
import {
	showingDropdownOrder,
	createString,
	getStatusFromInstallationOrGlobal,
	getText,
	parseActiveExtraProducts,
} from '../../../services';
import { Constants } from '../../../data';
import { getSteddiStatusBasedOnAccounts } from './Datahandling/UniqueServices';
import {
	IServicePageDataDescriptions,
	getCoreDetails,
	getServicePageDataDescriptions,
	getServicePageDataPitchCards,
	getServicePageDataSections,
	getServicePageHouseData,
} from './Datahandling';
import { IServiceOrderOrCancel } from '../../../modals';
import { v4 as uuid4 } from 'uuid';

export interface IServiceBasePageData extends IDefaultProps {
	onClickServiceHandling: (value: IServiceOrderOrCancel) => void;
	setInstallation?: (installation?: ICustomerInstallation) => void;
	account?: ICustomerAccountInformation;
	addonStates?: IAddonData[];
}

// ************************************
// Main
// ************************************

export const getServiceBasePageData = async (
	config: IServiceBasePageData
): Promise<IServiceBasePageView | undefined> => {
	if (config?.translations) {
		return {
			...config,
			sub: {
				title: _getTitle(config),
				back: _getBack(config),
			},
			localBrand: _getLocalBrand(config),
			banner: _getBanner(config),
			house: await _getHouse(config),
			sections: await _getSections(config),
			pitchCards: _getPitchCards(config),
			coreDetails: await _getCoreDetails(config),
			descriptions: _getDescriptions(config),
			dropOrder: _getDropOrder(config),
		} as IServiceBasePageView;
	}
};

// ************************************
// PRIVATE: Local brand
// ************************************

const _getLocalBrand = (config: IServiceBasePageData): string => {
	const { translations } = config;
	return `brand-${translations.servicePageType?.toLocaleLowerCase() ?? Constants.uiBrand}`;
};

// ************************************
// PRIVATE: Descriptions
// ************************************

const _getDescriptions = (config: IServiceBasePageData): IServicePageDataDescriptions | undefined => {
	const { translations, addonStates, user } = config;
	const { userData, installation } = user;

	if (translations && userData) {
		return getServicePageDataDescriptions(
			translations,
			_getStatus({
				userData,
				translations: translations,
				addonData: addonStates,
				installation,
			})
		);
	}
};

// ************************************
// PRIVATE: Core Details
// ************************************

const _getCoreDetails = async (config: IServiceBasePageData): Promise<IDetailsDescriptionBlock[] | undefined> => {
	const { translations, addonStates, user } = config;
	const { userData, installation } = user;

	if (userData && translations) {
		let details: IDetailsDescriptionBlock[] = await getCoreDetails(
			config,
			_getStatus({
				userData,
				translations: translations,
				addonData: addonStates,
				installation,
			})
		);
		return details;
	}
};

// ************************************
// PRIVATE: Sections
// ************************************

const _getSections = async (config: IServiceBasePageData): Promise<ISectionBlock[] | undefined> => {
	const { translations, user, addonStates } = config;
	const { userData, installation } = user;

	if (translations && userData) {
		let sections: ISectionBlock[] | undefined = await getServicePageDataSections(
			config,
			_getStatus({
				userData,
				translations: translations,
				addonData: addonStates,
				installation,
			})
		);

		return sections;
	}
};

// ************************************
// PRIVATE: Pitch Cards
// ************************************

const _getPitchCards = (config: IServiceBasePageData) => {
	const { translations, addonStates, user } = config;
	const { userData, installation } = user;

	if (translations && userData) {
		return getServicePageDataPitchCards(
			config,
			_getStatus({
				userData,
				translations: translations,
				addonData: addonStates,
				installation,
			})
		);
	}
};

// ************************************
// PRIVATE: Title
// ************************************

const _getTitle = (config: IServiceBasePageData): string => {
	return getText('pageTitle', config.translations);
};

// ************************************
// PRIVATE: Banner
// ************************************

const _getBanner = (config: IServiceBasePageData): IImage => {
	return {
		src: config.translations.imageSrc,
		alt: `${getText('pageTitle', config.translations)}`,
	} as IImage;
};

// ************************************
// PRIVATE: Back
// ************************************

const _getBack = (config: IServiceBasePageData): IAction => {
	return {
		text: getText('back', config.translations),
		link: `${Constants.paths.servicesPage}`,
	} as IAction;
};

// ************************************
// PRIVATE: House
// ************************************

const _getHouse = async (config: IServiceBasePageData): Promise<IPlankHouse | undefined> => {
	if (config) {
		return getServicePageHouseData(config);
	}
};

// ************************************
// PRIVATE: Drop Order
// ************************************

const _getDropOrder = (config: IServiceBasePageData): IHighlightedDropdownBlock | undefined => {
	const { translations, onClickServiceHandling, addonStates, user, relationship } = config;
	const { installation, userData } = user;
	const { isGuest } = relationship;

	if (
		installation &&
		translations?.serviceAdditionalAddonBlocks &&
		translations.serviceAdditionalAddonBlocks.length > 0 &&
		translations.allowOrder
	) {
		let status: ServiceStatus = _getStatus({
			userData,
			addonData: addonStates,
			translations: translations,
			installation,
		});
		let options: IOption[] = [];

		if (showingDropdownOrder(config, status)) {
			const activeExtraProductIds = parseActiveExtraProducts(installation, config.translations, addonStates);

			for (let additionalAddonService of translations.serviceAdditionalAddonBlocks) {
				if (additionalAddonService.active) {
					const serviceAlreadyActiveOnContract = activeExtraProductIds?.includes(
						Number(additionalAddonService.serviceId)
					);

					const optionText = `${getText('name', additionalAddonService, 'translationItems')}${
						serviceAlreadyActiveOnContract
							? '\n' +
							  getText('disabledDueToServiceAlreadyActive', additionalAddonService, 'translationItems')
							: ''
					}`;

					options.push({
						id: uuid4(),
						text: optionText,
						value: additionalAddonService.serviceId,
						disabled: serviceAlreadyActiveOnContract,
					});
				}
			}

			// Sorting the disabled addons last:
			options.sort((a, b) => {
				return Number(a.disabled) - Number(b.disabled);
			});

			let activeChoice: IOption = options[0];

			return {
				title: getText('dropOrderTitle', translations),
				description: createString(getText('dropOrderSubTitle', translations), {
					address: installation?.address.streetAddress,
					meterId: installation?.meterId,
				}),
				dropDownPlaceholder: getText('dropOrderPlaceholderText', translations),
				action: {
					disabled: isGuest,
					text: getText('order', translations),
					onClick: () => {
						onClickServiceHandling({
							status: status,
							userData,
							page: translations,
							installation,
							selectedSubService: activeChoice,
						});
					},
					icon: IconType.ExternalLinkThick,
				},
				onChange: (option: IOption) => {
					activeChoice = option;
				},
				options,
			};
		}
	}

	return undefined;
};

// ************************************
// HELPERS
// ************************************

interface IGetStatus {
	userData: ICustomer;
	installation?: ICustomerInstallation;
	translations: IServicePage;
	addonData?: IAddonData[];
}

const _getStatus = (config: IGetStatus): ServiceStatus => {
	const { translations, userData, installation, addonData } = config;

	let status: ServiceStatus | undefined = undefined;

	if (translations.servicePageId === 'forutsigbar') {
		status = getSteddiStatusBasedOnAccounts(userData.accounts);
	} else {
		status = getStatusFromInstallationOrGlobal({
			installationOnly: translations.orderMethodType === 'installation',
			translations: translations,
			installation,
			addonData,
		});
	}

	return status;
};
