import { handleAsyncData } from '../BaseService';
import { IDateState, ICustomerConsumption, ICustomerConsumptionPoint, CallState } from '../../models';
import { formatISO, getDaysInMonth } from 'date-fns';
import { DateResolution } from '../../models/types/Consumption';
import { downloadCsv } from './Utilities/CsvUtility';
import { typedGetRequest } from '../../contexts';
import { fetchGridTariff } from './GridTariffService';

export const fetchCustomerConsumption = async (meterId: string, config: IDateState, GETTYPED: typedGetRequest) => {
	let from = new Date(config.from);
	let to = new Date(config.to);

	if (config.resolution === 'hour') {
		to.setDate(to.getDate() + 1);
	}

	let fetchUrl = `Consumption/${meterId}/chart/${_getAdditions(config, config.resolution, from, to)}`;
	let consumption = await GETTYPED<ICustomerConsumptionPoint[]>(fetchUrl);

	if (consumption?.callState === 'success' && consumption?.data) {
		return consumption.data;
	} else {
		switch (config.resolution) {
			case 'month':
				return _getFallbackMonths(from, to);
			case 'day':
				return _getFallbackDays(from, to);
			case 'hour':
				return _getFallbackHours(from, to);
			default:
				return [] as ICustomerConsumptionPoint[];
		}
	}
};

const _getFallbackHours = (from: Date, to: Date) => {
	let data: ICustomerConsumptionPoint[] = [];

	for (let i = 0; i < 23; i++) {
		let fromDate: Date = new Date(from.getFullYear(), from.getMonth(), from.getDate(), i);
		let toDate: Date = new Date(from.getFullYear(), from.getMonth(), from.getDate(), i);

		data.push(_getConsumptionEmptyItem(fromDate, toDate));
	}

	return data;
};

const _getFallbackDays = (from: Date, to: Date) => {
	let data: ICustomerConsumptionPoint[] = [];
	let daysInMonth = getDaysInMonth(new Date(from.getFullYear(), from.getMonth(), 1));

	for (let i = 1; i < daysInMonth; i++) {
		let fromDate: Date = new Date(new Date(from.getFullYear(), from.getMonth(), i, 0));
		let toDate: Date = new Date(new Date(from.getFullYear(), from.getMonth(), i, 23));

		data.push(_getConsumptionEmptyItem(fromDate, toDate));
	}

	return data;
};

const _getFallbackMonths = (from: Date, to: Date) => {
	let data: ICustomerConsumptionPoint[] = [];

	for (let i = 0; i < 11; i++) {
		let daysInMonth = getDaysInMonth(new Date(from.getFullYear(), i, 1));
		let fromDate: Date = new Date(from.getFullYear(), i, 1);
		let toDate: Date = new Date(from.getFullYear(), i, daysInMonth);

		data.push(_getConsumptionEmptyItem(fromDate, toDate));
	}

	return data;
};

const _getConsumptionEmptyItem = (from: Date, to: Date) => {
	return {
		startTime: from.toDateString(),
		endTime: to.toDateString(),
		energy: {
			value: 0,
			amount: 0,
			isEstimated: false,
		},
		cost: {
			value: 0,
			amount: 0,
			isEstimated: false,
		},
		costIncludingSupport: {
			value: 0,
			amount: 0,
			isEstimated: false,
		},
	} as ICustomerConsumptionPoint;
};

export const fetchCustomerConsumptionAndGridTariff = async (
	meterId: string,
	config: IDateState,
	GETTYPED: typedGetRequest
) => {
	let customerConsumption = fetchCustomerConsumption(meterId, config, GETTYPED);
	let gridTariff = fetchGridTariff(meterId, config.from, GETTYPED);

	return await Promise.all([customerConsumption, gridTariff]).then((res) => {
		return {
			consumptionPoints: res[0],
			gridTariff: res[1]?.totalCost,
		} as ICustomerConsumption;
	});
};

export const fetchYearlyConsumption = async (meterId: string, years: number[], GETTYPED: typedGetRequest) => {
	let yearlyConsumption: ICustomerConsumptionPoint[][];
	let promises = years.map(async (year: number) => {
		let from: Date = new Date(year, 0);
		let to: Date = new Date(year, 11, getDaysInMonth(new Date(year, 11)));
		let config: IDateState = {
			from,
			to,
			resolution: 'month',
		};

		let resp = await fetchCustomerConsumption(meterId, config, GETTYPED);

		return resp;
	});

	yearlyConsumption = await Promise.all(promises).then((values: any) => {
		return values;
	});

	return yearlyConsumption;
};

const _getAdditions = (config: IDateState, resolution: DateResolution, from: Date, to: Date) => {
	let additionsStr = '';

	let fromDateString: string = resolution === 'hour' ? formatISO(from ?? new Date()) : from.toDateString();
	let toDateString: string = resolution === 'hour' ? formatISO(to ?? new Date()) : to.toDateString();

	if (config) {
		let additions = false;
		additionsStr += '?';

		if (resolution) {
			additionsStr += `resolution=${resolution}`;
			additions = true;
		}

		if (from) {
			additionsStr += additions
				? `&from=${encodeURIComponent(fromDateString)}`
				: `from=${encodeURIComponent(fromDateString)}`;
			additions = true;
		}

		if (to) {
			additionsStr += additions
				? `&to=${encodeURIComponent(toDateString)}`
				: `to=${encodeURIComponent(toDateString)}`;
		}
	}

	return additionsStr;
};

export const fetchConsumptionCsv = async (token: string, meterId: string, config: IDateState, fileName: string) => {
	let from = new Date(config.from);
	let to = new Date(config.to);
	to.setDate(to.getDate() + 1);

	let fetchUrl = `Consumption/${meterId}/csv${_getAdditions(config, 'hour', from, to)}`;

	let resp = await handleAsyncData({
		token,
		additions: fetchUrl,
		method: 'GET',
	});

	if (resp.callState === 'success') {
		downloadCsv({
			data: resp.data,
			fileName: fileName,
		});
		return 'success' as CallState;
	}

	return 'error' as CallState;
};
