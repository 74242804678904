import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { IPointPlankPrefab, SteddiPlankTemplate, getPlankPrefab } from '../../../../../Prefabs';
import { IMSPlankWall } from '../../../../../blocks';
import { ICustomerAccountInformation } from '../../../../../models';
import { createString, getText, redirectToSteddiPaymentFreeUrl } from '../../../../../services';
import { Constants } from '../../../../../data';
import { IServiceBasePageData } from '../../ServiceBasePageData';

// ************************************
// Predictable Payment planks
// ************************************

export const getPredictablePaymentPlanks = (config: IServiceBasePageData): IMSPlankWall[] => {
	const { user, services, translations, activeTheme } = config;
	const { userData } = user;
	const { GET, customerServiceFeature } = services;

	let walls: IMSPlankWall[] = [];

	if (userData.accounts.length > 0) {
		userData.accounts.forEach((account: ICustomerAccountInformation) => {
			let accountWall: IMSPlankWall = {
				planks: [],
			};

			if (account.steddiInfo) {
				accountWall.planks.push(
					getPlankPrefab('Point', {
						brand: 'brand-steddi',
						template: SteddiPlankTemplate(activeTheme ?? 'Light', 'top'),
						useDecimals: true,
						left: {
							title: getText('plankOverviewTitle', translations),
							description: createString(getText('plankOverviewDesc', translations), {
								amount: account.steddiInfo.monthlyAmount,
							}),
						},
						right: {
							description: createString(getText('plankOverviewTitleValue', translations), {
								accountNumber: account.accountId,
							}),
						},
						points: account.steddiInfo.balance,
						pointsLabel: getText('currency', translations),
					} as IPointPlankPrefab)
				);
				accountWall.planks.push(
					getPlankPrefab('Text', {
						left: {
							title: getText('plankLastTransactionsTitle', translations),
							description: getText('plankLastTransactionsDesc', translations),
						},
						right: {
							icon: IconType.ChevronRight,
						},
						action: {
							link: `${Constants.paths.predictablePaymentTransactions}/${account.accountId}`,
						},
					})
				);

				// KS FEATURE
				if (customerServiceFeature) {
					accountWall.planks.push(
						getPlankPrefab('Text', {
							left: {
								title: getText('plankPaymentFreeTitle', translations),
							},
							right: {
								icon: IconType.ExternalLinkThick,
							},
							action: {
								onClick: async () => {
									await redirectToSteddiPaymentFreeUrl(account.accountId, GET);
								},
							},
						})
					);
				}
			}

			if (accountWall.planks.length > 0) {
				walls.push(accountWall);
			}
		});
	}

	return walls;
};

/**
 * Returns active if Steddi data is found on any of the customers accounts
 */
export const getSteddiStatusBasedOnAccounts = (accounts: ICustomerAccountInformation[]): 'ACTIVE' | 'INACTIVE' => {
	return accounts.find((acc) => acc.steddiInfo) ? 'ACTIVE' : 'INACTIVE';
};
