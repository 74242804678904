import { useContext, createContext } from 'react';
import { IResponse, ITypedResponse } from '../../services';

export type getRequest = (additions: string, refetch?: boolean) => Promise<IResponse>;
export type typedGetRequest = <T>(additions: string, refetch?: boolean) => Promise<ITypedResponse<T>>;

export type postRequest = (additions: string, body?: any) => Promise<IResponse>;
export type typedPostRequest = <T>(additions: string, body?: any) => Promise<ITypedResponse<T>>;

export type putRequest = (additions: string, body?: any) => Promise<IResponse>;
export type typedPutRequest = <T>(additions: string, body?: any) => Promise<ITypedResponse<T>>;

export type deleteRequest = (additions: string, body: any) => Promise<IResponse>;
export type patchRequest = (additions: string, body: any) => Promise<IResponse>;
export type blobRequest = (additions: string, name?: string) => Promise<any>;

interface IApplicationServices {
	GET: getRequest;
	GETTYPED: typedGetRequest;
	POST: postRequest;
	POSTTYPED: typedPostRequest;
	PUT: putRequest;
	PUTTYPED: typedPutRequest;
	DELETE: deleteRequest;
	PATCH: patchRequest;
	BLOB: blobRequest;
	FORMPOST: (url: string, fields: { value: string; name: string }[]) => void;
	token: string;
	customerServiceFeature: boolean;
	setCustomerServiceFeature: (value: boolean) => void;
	user: any;
	clearCache: () => void;
	cacheCleared: () => boolean;
}

export const ApplicationServicehandlerContext = createContext<IApplicationServices>({
	GET: () => new Promise(() => {}),
	GETTYPED: () => new Promise<ITypedResponse<any>>(() => {}),
	POST: () => new Promise(() => {}),
	POSTTYPED: () => new Promise<ITypedResponse<any>>(() => {}),
	PUT: () => new Promise(() => {}),
	PUTTYPED: () => new Promise<ITypedResponse<any>>(() => {}),
	DELETE: () => new Promise(() => {}),
	PATCH: () => new Promise(() => {}),
	BLOB: () => new Promise(() => {}),
	FORMPOST: () => {},
	token: '',
	customerServiceFeature: false,
	setCustomerServiceFeature: (value: boolean) => {},
	user: undefined,
	clearCache: () => {},
	cacheCleared: () => false,
});

export const useApplicationServicehandlerContext = () => useContext(ApplicationServicehandlerContext);
